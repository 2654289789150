import { Component, OnInit } from '@angular/core';
import { AddAdvisorsService } from '../../services/add-advisors.service';
import { Advisors } from '../../model/advisors';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppUser } from '../../model/appuser';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-result-of-quiz-meet-financial',
  templateUrl: './result-of-quiz-meet-financial.component.html',
  styleUrls: ['./result-of-quiz-meet-financial.component.css'],
})
export class ResultOfQuizMeetFinancialComponent implements OnInit {
  advisorResult!: Advisors;
  emptyData = false;
  appUser: AppUser;

  constructor(
    private addAdvisorsService: AddAdvisorsService,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private user: UserService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState
      .pipe(map((user) => (this.appUser = user)))
      .subscribe();

    this.advisorResult = this.route.snapshot.data.advaisor;
    if (this.advisorResult) {
      this.emptyData = false;
    } else {
      this.emptyData = true;
    }
  }

  onClickWbsite(): void {
    const trackData = {
      userEmail: this.appUser?.email ?? 'unknown',
      userName: this.appUser?.displayName ?? 'unknown',
    };
    this.user
      .createUserTrack(
        trackData,
        this.appUser?.email,
        'advisor',
        this.advisorResult?.email
      )
      .subscribe((val: any) => { });
  }
}
