import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Countries } from '../../components/add-alternative/countries';
import { AddAdvisorsService } from '../../services/add-advisors.service';
import { Advisors } from '../../model/advisors';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Languages } from './Languages';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-add-advisor',
  templateUrl: './add-advisor.component.html',
  styleUrls: ['./add-advisor.component.css']
})
export class AddAdvisorComponent implements OnInit {

  AddAdvisorFormGroub!: FormGroup;
  matcher = new MyErrorStateMatcher();
  countries = Countries;
  searchTextOne = '';
  searchTextTwo = '';
  myControl = new FormControl();
  advisorId = '';
  warnMessage = false;
  languages = Languages;
  constructor(private formBuilder: FormBuilder, private afs: AngularFirestore,
              private addAdvisorService: AddAdvisorsService, private router: Router,
  ) { }

  ngOnInit(): void {
    this.advisorId = this.afs.createId();
    this.AddAdvisorFormGroub = this.formBuilder.group({
      NameFinancialAdvisor: ['', Validators.required],
      EmailFinancialAdvisor: ['', [Validators.required, Validators.email]],
      Language: ['', Validators.required],
      CalendlyLink: ['', Validators.required],
      Location: ['', Validators.required]
    });
  }
  onAddAdvior(): void {
    const nameFinancialAdvisor = this.AddAdvisorFormGroub.value.NameFinancialAdvisor;
    const emailFinancialAdvisor = this.AddAdvisorFormGroub.value.NameFinancialAdvisor;
    const language = this.AddAdvisorFormGroub.value.Language;
    const calendlyLink = this.AddAdvisorFormGroub.value.CalendlyLink;
    const location = this.AddAdvisorFormGroub.value.Location;
    if (nameFinancialAdvisor && emailFinancialAdvisor && language && calendlyLink && location) {
      this.warnMessage = false;
      const val = this.AddAdvisorFormGroub.value;
      const newAdvisor: Partial<Advisors> = {
        name: val.NameFinancialAdvisor,
        email: val.EmailFinancialAdvisor,
        language: val.Language,
        link: val.CalendlyLink,
        location: val.Location,
      };
      this.addAdvisorService.createAdvisor(newAdvisor, this.advisorId).pipe(tap(advisor => {
        this.router.navigateByUrl('/admin');
      }),
        catchError(err => {
          return throwError(err);
        })).subscribe();

    } else {
      this.warnMessage = true;
    }
  }
  onAddLanguage(): void {

  }

}
