import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { SuccessAddDialogComponent } from '../../components/success-add-dialog/success-add-dialog.component';
import { Countries } from '../../components/add-alternative/countries';
import { Initiative } from '../../model/initiative';
import { InitiativeService } from '../../services/initiative.service';

@Component({
  selector: 'app-add-initiative',
  templateUrl: './add-initiative.component.html',
  styleUrls: ['./add-initiative.component.css']
})
export class AddInitiativeComponent implements OnInit {
  AddInitiativeFormGroub!: FormGroup;
  countries = Countries;
  myControl = new FormControl();
  searchText = '';
  warnMessage = false;
  initiativeId!: string;
  constructor(private formBuilder: FormBuilder, private afs: AngularFirestore,
              private initiativeService: InitiativeService, private dialog: MatDialog
  ) { }
  ngOnInit(): void {
    this.initiativeId = this.afs.createId();
    this.AddInitiativeFormGroub = this.formBuilder.group({
      NameInitiative: ['', Validators.required],
      LinkInitiative: ['', Validators.required],
      ShortDescription: [''],
      Country: ['', Validators.required],

    });
  }
  onAddInitiative(): void {
    const nameInitiative = this.AddInitiativeFormGroub.value.NameInitiative;
    const linkInitiative = this.AddInitiativeFormGroub.value.LinkInitiative;
    const shortDescription = this.AddInitiativeFormGroub.value.ShortDescription;
    const country = this.AddInitiativeFormGroub.value.Country;


    if (nameInitiative && linkInitiative && country) {
      this.warnMessage = false;
      const val = this.AddInitiativeFormGroub.value;
      const newInitative: Partial<Initiative> = {
        name: val.NameInitiative,
        link: val.LinkInitiative,
        description: val.ShortDescription,
        country: val.Country,
      };
      this.initiativeService.createInitiative(newInitative, this.initiativeId).pipe(tap(initiative => {
        const dialogConfigSuccessAdd = new MatDialogConfig();
        dialogConfigSuccessAdd.disableClose = true;
        dialogConfigSuccessAdd.autoFocus = true;
        dialogConfigSuccessAdd.minWidth = '400px';
        this.dialog.open(SuccessAddDialogComponent, dialogConfigSuccessAdd)
          .afterClosed()
          .subscribe();
      })).subscribe();
    } else {
      this.warnMessage = true;
    }

  }
}
