import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post } from 'src/app/model/post';
import { BlogService } from 'src/app/services/blog.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit, OnDestroy {
  postData: Post = new Post();
  postId;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private _route: ActivatedRoute,
    private blogService: BlogService,
    private location: Location
  ) {
    if (this._route.snapshot.params.id) {
      this.postId = this._route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit(): void {
    this.blogService
      .getPostbyId(this.postId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: Post) => {
        this.postData = result;
      });
  }
  onBack(): void {
    this.location.back();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
