<mat-card class="container">
  <h1>{{ formTitle }} Post</h1>

  <hr />
  <form #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveBlogPost()" accept-charset="UTF-8" novalidate>

    <input type="text" class="blogHeader" placeholder="Add title..." class="form-control" name="postTitle"
      [(ngModel)]="postData.title" #postTitle="ngModel" required />

    <input type="url" class="blogHeader" placeholder="Add Photo URL..." class="form-control" name="imageURL"
      [(ngModel)]="postData.imageURL" #imageURL="ngModel" required />

    <span class="text-danger" *ngIf="myForm.submitted && imageURL.errors?.required">
      imageURL is required
    </span>
    <br />
    <div class="form-group">
      <ckeditor name="myckeditor" [config]="ckeConfig" [(ngModel)]="postData.content" #myckeditor="ngModel"
        debounce="300" [editor]="Editor"></ckeditor>
    </div>
    <div class="buttons">
      <button type="submit" mat-raised-button class="save-button">Save</button>
      <button type=" button" mat-raised-button color="warn" (click)="cancel()">
        CANCEL
      </button>
    </div>
  </form>
</mat-card>