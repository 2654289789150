import { Component, OnInit, OnDestroy } from '@angular/core';
import { Post } from '../../model/post';
import { AppUser } from '../../model/appuser';
import { BlogService } from '../../services/blog.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.css'],
})
export class BlogCardComponent implements OnInit, OnDestroy {
  config: any;
  pageSizeOptions = [];

  blogPost: Post[] = [];
  appUser: AppUser;
  private unsubscribe$ = new Subject<void>();

  constructor(private blogService: BlogService, public user: UserService) { }

  ngOnInit(): void {
    this.getBlogPosts();
  }

  getBlogPosts(): void {
    this.blogService
      .getAllPosts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.blogPost = result;
      });
  }

  onDeletePost(post: any): void {
    if (confirm(`Are you sure to delete this Blog : ${post.title} `)) {
      this.blogService
        .deleteBlog(post.postId)
        .pipe(
          catchError((err) => {
            alert('could not Delete alternative');
            return throwError(err);
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
