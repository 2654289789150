<section class="section-one">
    <section class="section-two">
        <img src="assets/savemoney.png" class="photo-save-money">
        <div class="section-title-and-p">
            <h1>{{ "BANK_SAVING_RULES.TITLE" | translate }}</h1>
            <p class="saving-money">
                {{ "BANK_SAVING_RULES.SECTION_ONE_PARAGRAPH_ONE" | translate }}
            </p>
        </div>
    </section>
    <section class="section-three">
        <div class="div-for-make-your-money">
            <p>
                {{ "BANK_SAVING_RULES.SECTION_ONE_PARAGRAPH_TWO" | translate }}
            </p>
            <ol type="1">
                <li>{{ "BANK_SAVING_RULES.STRATIEGE_ONE" | translate }}</li>
                <li>{{ "BANK_SAVING_RULES.STRATIEGE_TWO" | translate }}</li>
                <li> {{ "BANK_SAVING_RULES.STRATIEGE_THREE" | translate }}</li>
                <li>{{ "BANK_SAVING_RULES.STRATIEGE_FOUR" | translate }}</li>
                <li>{{ "BANK_SAVING_RULES.STRATIEGE_FIVE" | translate }} </li>
                <li> {{ "BANK_SAVING_RULES.STRATIEGE_SIX" | translate }}</li>
                <li> {{ "BANK_SAVING_RULES.STRATIEGE_SEVEN" | translate }}</li>
                <li>{{ "BANK_SAVING_RULES.STRATIEGE_EIGHT" | translate }}</li>
                <li> {{ "BANK_SAVING_RULES.STRATIEGE_NINE" | translate }}</li>
                <li> {{ "BANK_SAVING_RULES.STRATIEGE_TEN" | translate }}</li>

            </ol>
        </div>
    </section>
    <section class="section-four">
        <p>{{ "BANK_SAVING_RULES.SECTION_ONE_PARAGRAPH_THREE" | translate }}
            <a routerLink="/read"><b>full length blog post </b></a>
            {{ "BANK_SAVING_RULES.SECTION_ONE_PARAGRAPH_FOUR" | translate }}
        </p>
        <p>
            {{ "BANK_SAVING_RULES.SECTION_ONE_PARAGRAPH_FIVE" | translate }}
            <a routerLink="/alternatives/bank"><b>Here’s</b></a>
            {{ "BANK_SAVING_RULES.SECTION_ONE_PARAGRAPH_SIX" | translate }}

        </p>
    </section>
</section>