<div class="create-alternative">
    <h2>{{ "ADD_ALTERNATIVE.CREATE_ALTERNATIVE" | translate }}</h2>

    <form #form="ngForm" [formGroup]="alternativeform" (ngSubmit)="onAddAlternative()" class="form">
        <section class="form-section-one">
            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.TITLE" | translate }}</mat-label>
                <input [placeholder]='"ADD_ALTERNATIVE.TITLE" | translate' type="text" matInput formControlName="title">


            </mat-form-field>



            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.AREA_OF_OPERATION" | translate }}</mat-label>


                <input [placeholder]='"ADD_ALTERNATIVE.AREA_OF_OPERATION" | translate' type="text" matInput
                    formControlName="areaOfOperation">

            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.LOGO-LINK" | translate }}</mat-label>

                <input matInput [placeholder]='"ADD_ALTERNATIVE.LOGO-LINK" | translate' type="text"
                    formControlName="urlForLogo">

            </mat-form-field>


            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.LABEL" | translate }}</mat-label>
                <input matInput [placeholder]='"ADD_ALTERNATIVE.LABEL" | translate' type="text" formControlName="label">

            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.AMOUNT" | translate }}</mat-label>
                <input matInput [placeholder]='"ADD_ALTERNATIVE.AMOUNT" | translate' type="text"
                    formControlName="minimalAmount">

            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.BENIFITS" | translate }}</mat-label>

                <input matInput [placeholder]='"ADD_ALTERNATIVE.BENIFITS" | translate' type="text"
                    formControlName="benefitsOfTheInvestment">

            </mat-form-field>


            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.WEBSITE-LINK" | translate }} </mat-label>

                <input matInput [placeholder]='"ADD_ALTERNATIVE.WEBSITE-LINK" | translate' type="text"
                    formControlName="linkWebsite">

            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.DESCRIPTION" | translate }}</mat-label>

                <textarea matInput [placeholder]='"ADD_ALTERNATIVE.DESCRIPTION" | translate' type="text"
                    formControlName="longDescription"></textarea>

            </mat-form-field>

        </section>

        <section class="drop-down-form">


            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.COUNTRY" | translate }}</mat-label>
                <mat-select formControlName="country" multiple>

                    <input type="text" [placeholder]='"ADD_ALTERNATIVE.COUNTRY_PLACEHOLDER" | translate'
                        aria-label="Number" matInput [formControl]="myControl" [(ngModel)]="searchText"
                        autocomplete="off" class="input-for-search-country">


                    <mat-option *ngFor='let country of countries | filter:searchText' [value]="country.name">
                        {{country.name}}
                    </mat-option>
                </mat-select>

            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.CATEGORY" | translate }}</mat-label>

                <mat-select matNativeControl formControlName="category">


                    <mat-option value="Bank">Bank</mat-option>

                    <mat-option value="Investment">Investment</mat-option>
                    <mat-option value="Insurance">Insurance</mat-option>
                    <mat-option value="Pension Fund">Pension Fund</mat-option>
                </mat-select>
            </mat-form-field>
        </section>
        <section class="drop-down-form">
            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.FISCAL_ADVANTAGE" | translate }}</mat-label>
                <mat-select matNativeControl formControlName="fiscalAdvantage">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>

                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ "ADD_ALTERNATIVE.TYPE_OF_CUSTOMER" | translate }}</mat-label>

                <mat-select matNativeControl formControlName="typeOfCustomer">
                    <mat-option value="Retail">Retail</mat-option>
                    <mat-option value="Institutional">Institutional</mat-option>

                </mat-select>
            </mat-form-field>

        </section>
        <mat-form-field appearance="fill">
            <mat-label>if you are the owner of this initiative - tell us your email so that we can
                contact you
            </mat-label>

            <input matInput placeholder='You Email:' type="text">

        </mat-form-field>
        <p *ngIf=" warnMessage" class="warn-message">*Please fill out the important elements</p>
        <mat-hint class="error-message">{{errorMessage}}</mat-hint>

        <section class="add-alternative-button-and-back-button">

            <button mat-raised-button (click)="goBack()" class="back-button">
                <mat-icon aria-hidden="false" aria-label="Example arrow_back icon">arrow_back</mat-icon>
            </button>

            <button mat-raised-button color="warn" type="submit" class="add-button">
                {{ "ADD_ALTERNATIVE.ADD_ALTERNATIVE_BUTTON" | translate }}
            </button>


        </section>
    </form>


</div>