import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../services/user.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Advisors } from '../../model/advisors';
import { AddAdvisorsService } from '../../services/add-advisors.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditAdvisorDialgComponent } from '../edit-advisor-dialg/edit-advisor-dialg.component';

@Component({
  selector: 'app-advisors-card-list',
  templateUrl: './advisors-card-list.component.html',
  styleUrls: ['./advisors-card-list.component.css']
})
export class AdvisorsCardListComponent implements OnInit {
  @Input() advisors!: Advisors[] | null;
  @Output() advisorsEdited = new EventEmitter();

  @Output()
  advisorsDeleted = new EventEmitter<Advisors>();

  constructor(public user: UserService, private addAdvisorsService: AddAdvisorsService, private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }
  editAdvisor(advisor: any): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '400px';
    dialogConfig.data = advisor;
    this.dialog
      .open(EditAdvisorDialgComponent, dialogConfig)
      .afterClosed()
      .subscribe((val) => {
        if (val) {
          this.advisorsEdited.emit();
        }
      });
  }
  onDeleteAdvisor(advisor: any): void {
    this.addAdvisorsService.deleteAdvisor(advisor.id).pipe(
      tap(() => {
        this.advisorsDeleted.emit(advisor);
      }),
      catchError((err) => {
        alert('could not Delete advisor');
        return throwError(err);
      })
    ).subscribe();
  }
}


