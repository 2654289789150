<mat-hint class="error-message" *ngIf="showMessage">{{
  errorMessage
}}</mat-hint>

<section class="section-one">
  <mat-card
    *ngFor="let alternative of alternatives"
    class="alternative-card mat-elevation-z10"
  >
    <mat-card-header>
      <mat-card-title>{{ alternative.name }}</mat-card-title>
    </mat-card-header>

    <img [src]="alternative?.urlForLogo" class="image-of-alternative" />
    <mat-card-content>
      <h3>
        {{ "ALTERNATIVE_CARD.AREA_OF_OPERATION" | translate }}:
        {{
          alternative?.transAreaOfOperation
            ? alternative?.transAreaOfOperation[currentLang?.code]
              ? alternative?.transAreaOfOperation[currentLang?.code]
              : alternative?.areaOfOperation
            : alternative?.areaOfOperation
        }}
      </h3>

      <h3>
        {{ "ALTERNATIVE_CARD.COUNTRY" | translate }}: {{ alternative.country }}
      </h3>

      <p>
        {{
          alternative?.transDescription
            ? alternative?.transDescription[currentLang?.code]
              ? alternative?.transDescription[currentLang?.code]
              : alternative?.description
            : alternative?.description
        }}
      </p>
    </mat-card-content>

    <mat-card-actions class="alternative-actions">
      <button
        mat-button
        class="View-Alternative"
        (click)="viweDetail(alternative)"
      >
        {{ "ALTERNATIVE_CARD.VIEW_ALTERNATIVE_BUTTON" | translate }}
      </button>
      <div class="edit-delete-approve-buttons">
        <ng-container *ngIf="user.roles$ | async as roles">
          <ng-container *ngIf="roles.admin">
            <button
              mat-mini-fab
              class="edit-Alternative"
              (click)="editAlternative(alternative)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="isuser"
              class="checkbox-button"
              (click)="onCheckBox(alternative)"
            >
              <mat-icon>checkbox</mat-icon>
            </button>

            <button
              *ngIf="!isuser"
              mat-mini-fab
              class="add-language-icon"
              (click)="onAddLanguage(alternative)"
            >
              <mat-icon>language</mat-icon>
            </button>
            <button
              mat-mini-fab
              color="warn"
              (click)="onDeleteAlternative(alternative)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
        </ng-container>
      </div>
    </mat-card-actions>
  </mat-card>
</section>
