<section class="section-one" *ngIf="!emptyData">
    <h1 class="title">{{ "MEET_FINANCIAL.RESULT_TEXT" | translate }}</h1>
    <a href="{{advisorResult.link}}" target="_blank">
        <button mat-raised-button color="primary" (click)="onClickWbsite()" class="button-calendly">go to the
            calendly</button>
    </a>
</section>
<section class="section-two" *ngIf="emptyData">
    <p class="title">{{ "MEET_FINANCIAL.NO_RESULT_TEXT" | translate }}</p>

</section>