import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Alternative } from '../../model/alternative';
import { AlternativesService } from '../../services/alternatives.service';

import { FormControl, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alternatives',
  templateUrl: './alternatives.component.html',
  styleUrls: ['./alternatives.component.css']
})
export class AlternativesComponent implements OnInit {
  nameCountry = '';
  nameCategory = '';
  allCountry = [];
  emptyValue = false;
  labelPosition: 'before' | 'after' = 'after';
  showLoading = false;
  showMessage = false;
  showAddSuggestions = false;
  limit = 2;
  countryControl = new FormControl('', Validators.required);
  href = '';
  checkedOne = false;
  checkedTwo = false;
  checkedThree = false;
  checkedFour = false;
  alternativesList$!: Observable<Alternative[]>;

  constructor(private router: Router, private alternativesService: AlternativesService,
              private user: UserService, public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.href = this.router.url;
    if (this.router.url === '/alternatives/bank') {
      this.checkedOne = true;
      this.nameCategory = 'Bank';
    } else if (this.router.url === '/alternatives/pension-found') {
      this.checkedFour = true;
      this.nameCategory = 'Pension Fund';

    } else if (this.router.url === '/alternatives/investments') {
      this.checkedTwo = true;
      this.nameCategory = 'Investment';
    }

    this.alternativesService.AllAlternatives().subscribe(response => {
      const country: any = response.map(allAlternatives => {
        return allAlternatives.country;
      });
      const countries = [].concat(...country);
      const setOfcountry = [...new Set(countries)];
      this.allCountry = setOfcountry;
      return this.allCountry;
    });
    this.user.isLoggedIn$.subscribe((res) => res ? this.limit = Infinity : this.limit = 2);
  }
  changeNameCountry(event: any): void {

    this.nameCountry = event.target.innerText;

  }
  changeNameCategory(event: any): void {
    this.nameCategory = event.value;
  }
  search(): void {

    this.showLoading = true;
    if (this.nameCountry || this.nameCategory) {
      this.emptyValue = false;
    }
    if (!this.nameCountry || this.nameCategory) {
      this.emptyValue = false;
    }
    if (!this.nameCountry || !this.nameCategory) {
      this.emptyValue = true;
    }
    this.reloadlAlternatives();
    this.alternativesList$.subscribe((res) => {
      if (res.length === 0) {
        this.showMessage = true;
      }
      if (res.length >= 1) {
        this.showMessage = false;
      }
      this.showAddSuggestions = true;

    });
  }

  reloadlAlternatives(): void {

    this.alternativesList$ = this.alternativesService.loadAlternativesByfilter(this.nameCountry, this.nameCategory, this.limit);
    this.alternativesList$.subscribe(() => {
      this.showLoading = false;
    });

  }


}
