<section class="details-section">
  <mat-card class="alternative-card mat-elevation-z10">
    <mat-card-header>
      <mat-card-title class="title-name-of-alternative">{{
        alternativeDetails.name
      }}</mat-card-title>
    </mat-card-header>
    <img [src]="alternativeDetails?.urlForLogo" class="image-of-alternative" />

    <mat-card-content>
      <p>
        <b> {{ "ALTERNATIVE_DETAILS.COUNTRY" | translate }}:</b>
        {{ alternativeDetails.country }}
      </p>
      <p>
        <b> {{ "ALTERNATIVE_DETAILS.AREA_OF_OPERATION" | translate }}:</b>
        {{
          alternativeDetails?.transAreaOfOperation
            ? alternativeDetails?.transAreaOfOperation[currentLang?.code]
              ? alternativeDetails?.transAreaOfOperation[currentLang?.code]
              : alternativeDetails?.areaOfOperation
            : alternativeDetails?.areaOfOperation
        }}
      </p>
      <p>
        <b> {{ "ALTERNATIVE_DETAILS.TYPE_OF_CUSTOMER" | translate }}:</b>
        {{
          alternativeDetails?.transTypeOfCustomer
            ? alternativeDetails?.transTypeOfCustomer[currentLang?.code]
              ? alternativeDetails?.transTypeOfCustomer[currentLang?.code]
              : alternativeDetails?.typeOfCustomer
            : alternativeDetails?.typeOfCustomer
        }}
      </p>
      <p>
        <b> {{ "ALTERNATIVE_DETAILS.LABEL" | translate }}:</b>
        {{
          alternativeDetails?.transLabel
            ? alternativeDetails?.transLabel[currentLang?.code]
              ? alternativeDetails?.transLabel[currentLang?.code]
              : alternativeDetails?.label
            : alternativeDetails?.label
        }}
      </p>
      <p>
        <b> {{ "ALTERNATIVE_DETAILS.FISCAL_ADVANTAGE" | translate }}:</b>
        {{ alternativeDetails.fiscalAdvantage }}
      </p>
      <p>
        <b> {{ "ALTERNATIVE_DETAILS.AMOUNT_TO_INVEST" | translate }}:</b>
        {{ alternativeDetails.amountToInvest }}
      </p>
      <p>
        <b> {{ "ALTERNATIVE_DETAILS.BENIFITS" | translate }}:</b>
        {{
          alternativeDetails?.transBenefitsOfTheInvestment
            ? alternativeDetails?.transBenefitsOfTheInvestment[
                currentLang?.code
              ]
              ? alternativeDetails?.transBenefitsOfTheInvestment[
                  currentLang?.code
                ]
              : alternativeDetails?.benefitsOfTheInvestment
            : alternativeDetails?.benefitsOfTheInvestment
        }}
      </p>
      <p>
        <b> {{ "ALTERNATIVE_DETAILS.DESCRIPTION" | translate }}:</b>
        {{
          alternativeDetails?.transDescription
            ? alternativeDetails?.transDescription[currentLang?.code]
              ? alternativeDetails?.transDescription[currentLang?.code]
              : alternativeDetails?.description
            : alternativeDetails?.description
        }}
      </p>
    </mat-card-content>
    <ng-container *ngIf="user.user$ | async as user">
      <div class="section-button">
        <a [href]="href" [target]="target">
          <button
            mat-raised-button
            color="primary"
            (click)="onClickWbsite(user)"
            class="website-button"
          >
            {{ "ALTERNATIVE_DETAILS.VIST_THE_WEBSITE_BUTTON" | translate }}
          </button>
        </a>
      </div>
    </ng-container>
  </mat-card>
</section>
<section class="button-back-section">
  <button mat-raised-button (click)="onBack()" class="back-button">
    <mat-icon aria-hidden="false" aria-label="Example arrow_back icon"
      >arrow_back</mat-icon
    >
  </button>
</section>
