import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Alternative } from '../../model/alternative';
import { AlternativesService } from '../../services/alternatives.service';
import { UserService } from '../../services/user.service';
import { UserAlternativesService } from '../../services/user-alternatives.service';
import { TranslateService } from '@ngx-translate/core';
import { InitiativeService } from '../../services/initiative.service';
import { Initiative } from '../../model/initiative';
import { Advisors } from '../../model/advisors';
import { AddAdvisorsService } from '../../services/add-advisors.service';
@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css'],

})
export class AdminPageComponent implements OnInit {
  warnMessageOne = false;
  warnMessageTwo = false;
  warnMessageThree = false;
  warnMessageFour = false;
  warnMessageFive = false;

  alternativesList$!: Observable<Alternative[]>;
  userAlternativsList$!: Observable<Alternative[]>;
  initiativeList$!: Observable<Initiative[]>;
  initiativeListAdmin$!: Observable<Initiative[]>;
  advisorsList$!: Observable<Advisors[]>;

  searchText = '';
  loadingShowOne = false;
  loadingShowTwo = false;
  loadingShowThree = false;
  loadingShowFour = false;
  loadingShowFive = false;

  currentLang: any;

  constructor(private router: Router,
              private alternativesService: AlternativesService,
              public user: UserService,
              private userAlternatives: UserAlternativesService,
              public translate: TranslateService,
              private initiativeService: InitiativeService,
              private addAdvisorsService: AddAdvisorsService


  ) {

  }

  ngOnInit(): void {

  }

  reloadlAternatives(): void {
    this.alternativesList$ = this.alternativesService.AllAlternatives();
    this.alternativesList$.subscribe((res) => {
      if (res.length === 0) {

        this.loadingShowOne = false;
        this.warnMessageOne = true;

      } else {
        this.loadingShowOne = false;
        this.warnMessageOne = false;
      }


    });

  }
  reloadUserAlternatives(): void {
    this.userAlternativsList$ = this.userAlternatives.AllUserAlternatives();
    this.userAlternativsList$.subscribe((res) => {
      if (res.length === 0) {
        this.loadingShowTwo = false;
        this.warnMessageTwo = true;
      } else {
        this.loadingShowTwo = false;
        this.warnMessageTwo = false;
      }
    });
  }
  reloadlInitiative(): void {
    this.initiativeList$ = this.initiativeService.AllInitative();
    this.initiativeList$.subscribe(res => {
      if (res.length === 0) {
        this.loadingShowThree = false;
        this.warnMessageThree = true;


      } else {
        this.loadingShowThree = false;
        this.warnMessageThree = false;


      }
    });
  }
  reloadlInitiativeAdmin(): void {
    this.initiativeListAdmin$ = this.initiativeService.AllInitativeAdmin();
    this.initiativeListAdmin$.subscribe(res => {
      if (res.length === 0) {
        this.loadingShowFour = false;
        this.warnMessageFour = true;

      } else {
        this.loadingShowFour = false;
        this.warnMessageFour = false;

      }
    });
  }
  reloadlAdvisors(): void {
    this.advisorsList$ = this.addAdvisorsService.Alladvisors();
    this.advisorsList$.subscribe(res => {
      if (res.length === 0) {
        this.loadingShowFive = false;
        this.warnMessageFive = true;

      } else {
        this.loadingShowFive = false;
        this.warnMessageFive = false;

      }
    });

  }
  tabClick(event: any): void {
    this.loadingShowOne = true;
    this.loadingShowTwo = true;
    this.loadingShowThree = true;
    this.loadingShowFour = true;
    this.loadingShowFive = true;
    this.reloadlAternatives();
    this.reloadUserAlternatives();
    this.reloadlInitiative();
    this.reloadlInitiativeAdmin();
    this.reloadlAdvisors();

  }


}
