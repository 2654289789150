import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-add-alternative',
  templateUrl: './admin-add-alternative.component.html',
  styleUrls: ['./admin-add-alternative.component.css']
})
export class AdminAddAlternativeComponent implements OnInit {

  isAdmin = true;
  constructor() { }

  ngOnInit(): void {
  }

}
