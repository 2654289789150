import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alternative } from '../../model/alternative';
import { UserAlternativesService } from '../../services/user-alternatives.service';
@Component({
  selector: 'app-edit-user-alternatives-dialog',
  templateUrl: './edit-user-alternatives-dialog.component.html',
  styleUrls: ['./edit-user-alternatives-dialog.component.css']
})
export class EditUserAlternativesDialogComponent implements OnInit {
  form: FormGroup;
  alternative: Alternative;
  constructor(private dialogRef: MatDialogRef<EditUserAlternativesDialogComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) alternative: Alternative,
              private userAlternativesService: UserAlternativesService

  ) {

    this.alternative = alternative;
    this.form = this.fb.group({
      name: [alternative.name],
      description: [alternative.description],
      country: [alternative.country],
      areaOfOperation: [alternative.areaOfOperation],
      url: [alternative.urlForLogo],
      label: [alternative.label],
      amountToInvest: [alternative.amountToInvest],
      benefitsOfTheInvestment: [alternative.benefitsOfTheInvestment],
      Website: [alternative.Website],
      overallCategory: [alternative.overallCategory],
      fiscalAdvantage: [alternative.fiscalAdvantage],
      typeOfCustomer: [alternative.typeOfCustomer],

    });

  }
  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close();

  }
  save(): void {
    const changes = this.form.value;


    this.userAlternativesService.updataUserAlternativesById(this.alternative.id, changes).subscribe(() => {
      this.dialogRef.close(changes);
    });
  }
}

