<section class="section-one">
  <img src="assets/impactMoney.png" />
  <section class="section-two">
    <h1> {{ "IMPACT_YOUR_MONEY.TITLE" | translate }}</h1>
    <p>
        {{ "IMPACT_YOUR_MONEY.SECTION_ONE_PARAGRAPH_ONE" | translate }}
    </p>
    <p>
        {{ "IMPACT_YOUR_MONEY.SECTION_ONE_PARAGRAPH_TWO" | translate }}
    </p>
    <p>{{ "IMPACT_YOUR_MONEY.SECTION_ONE_PARAGRAPH_THREE" | translate }}</p>
    <ul>
      <li>
        For listed investments:<a
          href="https://www.myfairmoney.eu/fund-database"
        >
          https://www.myfairmoney.eu/fund-database</a
        >
      </li>
      <li>
        For banks:<a href=" https://fairfinanceguide.org/">
          https://fairfinanceguide.org/</a
        >
      </li>
      <li>Some labels - blog post coming soon</li>
      <li>The EU taxonomy - blog post coming soon</li>
    </ul>
    <p>
      And if you have any other questions, feel free to email:
      <a href="mailto:diane@mymoneymyplanet.org">diane@mymoneymyplanet.org </a>
      and we’ll be happy to help.
    </p>
  </section>
</section>
