import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransAlternative } from '../../model/transAlternative';
import { AddLangService } from '../../services/add-lang.service';
import { Alternative } from '../../model/alternative';

@Component({
  selector: 'app-add-language-dialog',
  templateUrl: './add-language-dialog.component.html',
  styleUrls: ['./add-language-dialog.component.css']
})
export class AddLanguageDialogComponent implements OnInit {
  form: FormGroup;
  alternative: Alternative;
  translateValue: any;

  constructor(private dialogRef: MatDialogRef<AddLanguageDialogComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) alternative: Alternative, private addLangService: AddLangService) {
    this.alternative = alternative;
    this.form = this.fb.group({
      dropListLang: [''],
      transDescription: [alternative.description],
      transAreaOfOperation: [alternative.areaOfOperation],
      transLabel: [alternative.label],
      transBenefitsOfTheInvestment: [alternative.benefitsOfTheInvestment],
      transTypeOfCustomer: [alternative.typeOfCustomer],

    });
  }

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close();
  }
  save(): void {

    const dropListLang = this.form.value.dropListLang;
    const formValues = this.form.value;

    this.translateValue = {
      transDescription: {
        [dropListLang]: formValues.transDescription
      },
      transAreaOfOperation: {
        [dropListLang]: formValues.transAreaOfOperation
      },
      transLabel: {
        [dropListLang]: formValues.transLabel
      },
      transBenefitsOfTheInvestment: {
        [dropListLang]: formValues.transBenefitsOfTheInvestment
      },
      transTypeOfCustomer: {
        [dropListLang]: formValues.transTypeOfCustomer
      },
    };


    this.addLangService.addAlternativeLang(this.alternative.id, this.translateValue)
      .subscribe(() => {
        this.dialogRef.close(this.translateValue);
      });
  }
}
