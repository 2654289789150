<mat-toolbar class="header-container">
  <a routerLink="" class="anchor-for-mymoneymyplanet-logo">
    <img src="assets/mymoneymyplant.png" class="logo-for-mymoneymyplanet" />
  </a>
  <a routerLink="/read" class="header-title">
    {{ "HEADER.READ_TITLE" | translate }}</a>

  <a routerLink="/initiatives" class="header-title">{{
    "HEADER.GET-INVOLVED_TITLE" | translate
    }}</a>
  <a routerLink="/alternatives" class="header-title">{{
    "HEADER.ACT_TITLE" | translate
    }}</a>
  <a routerLink="/measure" class="header-title">{{
    "HEADER.MEASURE_TITLE" | translate
    }}</a>

  <button mat-button [matMenuTriggerFor]="belowMenu">
    {{ buttonValue }}

    <mat-icon aria-hidden="false" aria-label="arrow_drop_down" class="arrow">arrow_drop_down</mat-icon>
  </button>

  <mat-menu #belowMenu="matMenu" yPosition="below">
    <button mat-menu-item *ngFor="let languages of languagesList" (click)="changeCurrentLang(languages)">
      {{ languages.name }}
    </button>
  </mat-menu>

  <div>
    <button mat-raised-button *ngIf="user.isLoggedOut$ | async" routerLink="/login" class="button-for-login">
      {{ "HEADER.LOG_IN" | translate }}
    </button>

    <button mat-button [matMenuTriggerFor]="menu">
      <ng-container *ngIf="user.pictureUrl$ | async as pictureUrl">
        <img class="user-avatar" [src]="pictureUrl" />
      </ng-container>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngIf="user.user$ | async as user">
        <button mat-menu-item>{{ user.displayName }}</button>
      </ng-container>

      <ng-container *ngIf="user.roles$ | async as roles">
        <ng-container *ngIf="!roles.admin">
          <button mat-menu-item routerLink="/user-profile">{{ "HEADER.USER_PROFILE" | translate }}</button>

        </ng-container>
        <ng-container *ngIf="roles.admin">
          <button mat-menu-item routerLink="/admin">{{ "HEADER.ADMIN_PAGE" | translate }}</button>
        </ng-container>
      </ng-container>
      <button mat-menu-item mat-list-item class="button-for-logout" *ngIf="user.isLoggedIn$ | async" (click)="logout()">
        {{ "HEADER.LOG_OUT" | translate }}
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>