<section class="section-one">
    <mat-card *ngFor="let advisor of advisors" class="advisor-card mat-elevation-z10">

        <mat-card-header>

            <mat-card-title>{{advisor.name}}</mat-card-title>

        </mat-card-header>

        <mat-card-content>
            <h3>Emial: {{advisor.email}}</h3>
            <h3>Location: {{advisor.location}}</h3>
            <h3>Language: {{advisor.language}}</h3>
            <a [href]="advisor.link" target="_blank">
                <button mat-raised-button color="primary" class="link-calendly">Link of calendly</button>
            </a>

        </mat-card-content>

        <mat-card-actions class="advisor-actions">
            <div class="edit-delete-buttons">
                <ng-container *ngIf="(user.roles$ | async) as roles">
                    <ng-container *ngIf="roles.admin">
                        <button mat-mini-fab class="edit-advisor" (click)="editAdvisor(advisor)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-mini-fab color="warn" (click)="onDeleteAdvisor(advisor)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-container>
                </ng-container>


            </div>

        </mat-card-actions>

    </mat-card>
</section>