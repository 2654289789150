import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { Advisors } from '../model/advisors';
import { convertSnaps } from './db-utils';
import { concatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddAdvisorsService {

  constructor(private db: AngularFirestore) { }

  createAdvisor(newAdvisor: Partial<Advisors>, advisorId?: string): Observable<any> {
    return this.db.collection('advisors',
      ref => ref.orderBy('seqNo', 'desc').limit(1))
      .get()
      .pipe(concatMap(result => {
        const advisors = convertSnaps<Advisors>(result);
        const lastadvisorSeqNo = advisors[0]?.seqNo ?? 0;
        const advisor = {
          ...newAdvisor,
          seqNo: lastadvisorSeqNo + 1
        };
        let save$: Observable<any>;
        // tslint:disable-next-line:prefer-conditional-expression
        if (advisorId) {
          save$ = from(this.db.doc(`advisors/${advisorId}`).set(advisor));
        }
        else {
          save$ = from(this.db.collection('advisors').add(advisor));

        }

        return save$
          .pipe(map(res => {
            return {
              id: advisorId ?? res.id,
              ...advisor
            };
          }));
      })
      );

  }
  Alladvisors(): Observable<Advisors[]> {
    return this.db.collection('advisors').get().pipe(map((result) => convertSnaps<Advisors>(result)));

  }
  findAdvisorByUrl(Language: any, Location: any): Observable<any | null> {
    return this.db
      .collection('advisors', (ref) =>
        ref.where('location', 'array-contains', Location)
      )
      .get()
      .pipe(
        map((result) => {
          const advisors = convertSnaps<Advisors>(result);

          const advisorFilter = advisors.filter((advisor) => {
            return advisor.language.indexOf(Language) > -1;
          });
          return advisorFilter.length === 1 ? advisorFilter[0] : null;
        })
      );
  }
  deleteAdvisor(advisorId: string): Observable<void> {
    return from(this.db.doc(`advisors/${advisorId}`).delete());
  }

  updataAdvisorsById(advisorsId: string, changes: Partial<Advisors>): Observable<void> {
    return from(this.db.doc(`advisors/${advisorsId}`).update(changes));

  }
}

