export const Languages =
    [
        {
            name: 'English', value: '1'
        },
        {
            name: 'Dutch', value: '2'
        }, {
            name: 'Spanish', value: '3'
        }, {
            name: 'French', value: '4'
        }, {
            name: 'Italian', value: '5'
        }, {
            name: 'Polish', value: '6'
        }, {
            name: 'Russian', value: '7'
        }, {
            name: 'Turkish', value: '8'
        }, {
            name: 'German', value: '9'
        },
        {
            name: 'Ukrainian', value: '10'
        },


    ];
