<section class="section-one">
  <h1 class="title-one">{{ "ABOUT_US.TITLE-ONE" | translate }}</h1>
  <section class="container">
    <section>
      <img src="assets/diane.png" />
      <h4>{{ "ABOUT_US.IMAGE_CAPTION" | translate }}</h4>
    </section>
    <section class="section-two">
      <p>
        {{ "ABOUT_US.SECTION_TWO_PARAGRAPH_ONE" | translate }}
      </p>
      <p>
        {{ "ABOUT_US.SECTION_TWO_PARAGRAPH_TWO" | translate }}
      </p>
      <p>
        {{ "ABOUT_US.SECTION_TWO_PARAGRAPH_THREE" | translate }}
      </p>
      <p>
        {{ "ABOUT_US.SECTION_TWO_PARAGRAPH_FOUR" | translate }}
      </p>
      <p>
        {{ "ABOUT_US.SECTION_TWO_PARAGRAPH_FIVE" | translate }}
      </p>
      <p>
        {{ "ABOUT_US.REACH-OUT" | translate }}
        <a href="mailto:diane@mymoneymyplanet.org">diane@mymoneymyplanet.org</a>
      </p>
    </section>
  </section>
</section>
