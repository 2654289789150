<div class="add-post-container">

  <img class="photo-earth" src="assets/titleandphoto.png">

  <ng-container *ngIf="user.roles$ | async as roles">
    <ng-container *ngIf="roles.admin">
      <button mat-raised-button color="primary" class="Add-post" routerLink="/add-post">
        Add Post
        <mat-icon class="add-course-btn">add_circle</mat-icon>
      </button>
    </ng-container>
  </ng-container>
</div>
<section class="section-one">
  <mat-card *ngFor="let post of blogPost" class="blog-card mat-elevation-z10">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title><a class="blog-title" [routerLink]="['/blog/', post.postId, post.title | slug]">
          <h2>{{ post.title }}</h2>
        </a></mat-card-title>
    </mat-card-header>
    <img mat-card-image [src]="post?.imageURL" alt="Photo of a Blog" />
    <mat-card-content>
      <div [innerHTML]="post.content | excerpt"></div>
    </mat-card-content>
    <mat-card-actions class="edit-delete-approve-buttons">
      <ng-container *ngIf="user.roles$ | async as roles">
        <ng-container *ngIf="roles.admin">
          <button mat-mini-fab color="warn" (click)="onDeletePost(post)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-mini-fab class="edit-post" [routerLink]="['/editpost', post.postId]">
            <mat-icon>edit</mat-icon>
          </button>
        </ng-container>
      </ng-container>

      <button mat-button class="View-post" [routerLink]="['/blog/', post.postId, post.title | slug]">
        Read More
      </button>
    </mat-card-actions>
  </mat-card>
</section>