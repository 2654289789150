import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import { AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';


import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AlternativesComponent } from './pages/alternatives/alternatives.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AlternativesCardListComponent } from './components/alternatives-card-list/alternatives-card-list.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { EditAlternativeDialogComponent } from './components/edit-alternative-dialog/edit-alternative-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddAlternativeComponent } from './components/add-alternative/add-alternative.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AlternativeDetilesPageComponent } from './pages/alternative-detiles-page/alternative-detiles-page.component';
import { LoginComponent } from './pages/login/login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserSuggestionsComponent } from './pages/user-suggestions/user-suggestions.component';
import { AdminAddAlternativeComponent } from './pages/admin-add-alternative/admin-add-alternative.component';
import { UserAddAlternativeComponent } from './pages/user-add-alternative/user-add-alternative.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { QuizGitStartComponent } from './pages/quiz-git-start/quiz-git-start.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BanksAndSavingsRulesPageComponent } from './pages/banks-and-savings-rules-page/banks-and-savings-rules-page.component';
import { QuizMeetFinancialAdvisorComponent } from './pages/quiz-meet-financial-advisor/quiz-meet-financial-advisor.component';
import { ResultOfQuizMeetFinancialComponent } from './pages/result-of-quiz-meet-financial/result-of-quiz-meet-financial.component';
import { AddAdvisorComponent } from './pages/add-advisor/add-advisor.component';
import { AddLanguageDialogComponent } from './components/add-language-dialog/add-language-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BlogEditorComponent } from './pages/blog-editor/blog-editor.component';
import { BlogCardComponent } from './pages/blog-card/blog-card.component';
import { Excerpt } from './customPipes/excerpt';
import { Slug } from './customPipes/slug';
import { BlogComponent } from './pages/blog/blog.component';
import { InitiativesComponent } from './pages/initiatives/initiatives.component';
import { AddInitiativeComponent } from './pages/add-initiative/add-initiative.component';
import { InitiativsCardListComponent } from './components/initiativs-card-list/initiativs-card-list.component';
import { AddLanguageInitiativeComponent } from './components/add-language-initiative/add-language-initiative.component';
import { EditInitiativeDialogComponent } from './components/edit-initiative-dialog/edit-initiative-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { PensionFundPageComponent } from './pages/pension-fund-page/pension-fund-page.component';
import { InvestmentsPageComponent } from './pages/investments-page/investments-page.component';
import { ImpactYourMoneyComponent } from './pages/impact-your-money/impact-your-money.component';
import { LastStepComponent } from './pages/last-step/last-step.component';
import { AlternativeInvestmentsComponent } from './pages/alternative-investments/alternative-investments.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { MeasureComponent } from './pages/measure/measure.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { EditUserAlternativesDialogComponent } from './components/edit-user-alternatives-dialog/edit-user-alternatives-dialog.component';
import { AdvisorsCardListComponent } from './components/advisors-card-list/advisors-card-list.component';
import { EditAdvisorDialgComponent } from './components/edit-advisor-dialg/edit-advisor-dialg.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AlternativesComponent,
    HomePageComponent,
    AlternativesCardListComponent,
    AdminPageComponent,
    EditAlternativeDialogComponent,
    AddAlternativeComponent,
    AlternativeDetilesPageComponent,
    LoginComponent,
    UserSuggestionsComponent,
    AdminAddAlternativeComponent,
    UserAddAlternativeComponent,
    QuizGitStartComponent,
    BanksAndSavingsRulesPageComponent,
    QuizMeetFinancialAdvisorComponent,
    ResultOfQuizMeetFinancialComponent,
    AddAdvisorComponent,
    AddLanguageDialogComponent,
    BlogEditorComponent,
    BlogCardComponent,
    Excerpt,
    Slug,
    BlogComponent,
    InitiativesComponent,
    AddInitiativeComponent,
    InitiativsCardListComponent,
    AddLanguageInitiativeComponent,
    EditInitiativeDialogComponent,
    FooterComponent,
    PensionFundPageComponent,
    InvestmentsPageComponent,
    ImpactYourMoneyComponent,
    LastStepComponent,
    AlternativeInvestmentsComponent,
    AboutUsComponent,
    MeasureComponent,
    UserProfileComponent,
    EditUserAlternativesDialogComponent,
    AdvisorsCardListComponent,
    EditAdvisorDialgComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MatTabsModule,
    MatFormFieldModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatInputModule,
    Ng2SearchPipeModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    MatProgressSpinnerModule,
    HttpClientModule, // the change from http module
    MatStepperModule,
    MatCheckboxModule,
    CKEditorModule,
    TranslateModule.forRoot({

      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })


  ],
  providers: [
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// tslint:disable-next-line:typedef
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
