<h2 mat-dialog-title>Edit Advisor</h2>

<mat-dialog-content [formGroup]="form">

    <mat-form-field>

        <input matInput placeholder=" Title" formControlName="name">

    </mat-form-field>

    <mat-form-field>

        <input matInput placeholder="Email" formControlName="email">

    </mat-form-field>
    <mat-form-field>

        <input matInput placeholder="Location" formControlName="location">

    </mat-form-field>
    <mat-form-field>


        <input matInput placeholder="Language" type="text" formControlName="language">

    </mat-form-field>
    <mat-form-field>


        <input matInput placeholder="Link" type="text" formControlName="link">

    </mat-form-field>



</mat-dialog-content>

<mat-dialog-actions>

    <button mat-raised-button (click)="close()" class="close-form">
        Close
    </button>

    <button mat-raised-button color="primary" (click)="save()" class="save-Advisor">
        Save
    </button>

</mat-dialog-actions>