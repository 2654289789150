
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Alternative } from '../model/alternative';
import { AlternativesService } from './alternatives.service';
import { Observable } from 'rxjs';
import { UserAlternativesService } from './user-alternatives.service';

@Injectable({
    providedIn: 'root'
})
export class AlternativeResolver implements Resolve<Alternative>{

    constructor(private alternativeService: AlternativesService) { }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<Alternative> {

        const alternativeName = route.paramMap.get('alternativeName');

        return this.alternativeService.findAlternativeByUrl(alternativeName);
    }


}
