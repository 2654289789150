import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Initiative } from '../../model/initiative';
import { InitiativeService } from '../../services/initiative.service';
@Component({
  selector: 'app-initiatives',
  templateUrl: './initiatives.component.html',
  styleUrls: ['./initiatives.component.css']
})
export class InitiativesComponent implements OnInit {
  countryControl = new FormControl('', Validators.required);
  initiativeList$!: Observable<Initiative[]>;
  allCountry = [];
  nameCountry = '';
  limit = 2;
  showLoading = false;
  warnMesaage = false;
  showAddSuggestions = false;

  constructor(private initiativeService: InitiativeService) { }

  ngOnInit(): void {
    this.initiativeService.AllInitativeAdmin().subscribe((res) => {
      const country: any = res.map(allInitiative => {
        return allInitiative.country;
      });
      const countries = [].concat(...country);
      const setOfcountry = [...new Set(countries)];
      this.allCountry = setOfcountry;
      return this.allCountry;
    });

  }
  changeNameCountry(event: any): void {
    this.nameCountry = event.target.innerText;


  }
  search(): void {
    this.showLoading = true;
    if (this.nameCountry) {
      this.warnMesaage = false;
    } else if (!this.nameCountry) {
      this.warnMesaage = true;
    }
    this.reloadlInitiative();
    this.initiativeList$.subscribe((res) => {
      if (res.length === 0) {
        this.warnMesaage = true;
      }
      if (res.length >= 1) {
        this.warnMesaage = false;
      }
      this.showAddSuggestions = true;

    });
  }
  reloadlInitiative(): void {
    this.initiativeList$ = this.initiativeService.loadInitativesByfilter(this.nameCountry, this.limit);
    this.initiativeList$.subscribe(() => {
      this.showLoading = false;
    });
  }
}
