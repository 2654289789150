<section class="section-two">
    <h1 class="title-quiz">{{ "QUIZ_MEET_ADVISOR.QUIZ_TITLE" | translate }}</h1>
    <form #form="ngForm" [formGroup]="QuizMeerFinancialFormGroub" class="form-quiz" (ngSubmit)="onSubmitQuiz()">
        <section class="section-form">
            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label>{{ "QUIZ_MEET_ADVISOR.NAME_FIELD" | translate }}*</mat-label>
                <input matInput #message maxlength="256" placeholder="Your name...." formControlName="Name">
            </mat-form-field>

            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label>{{ "QUIZ_MEET_ADVISOR.EMAIL_FIELD" | translate }}*</mat-label>
                <input matInput #message maxlength="256" placeholder="Your Email...." formControlName="Email"
                    [errorStateMatcher]="matcher">
                <mat-error
                    *ngIf="QuizMeerFinancialFormGroub.hasError('email') && !QuizMeerFinancialFormGroub.hasError('required')">
                    {{ "QUIZ_MEET_ADVISOR.EMAIL-VALIDATE" | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label>{{ "QUIZ_MEET_ADVISOR.AGE_FIELD" | translate }}</mat-label>
                <input matInput #message maxlength="256" placeholder="Your AGE...." formControlName="Age">
            </mat-form-field>

            <section class="section-all-qustions">
                <label class="qustions">{{ "QUIZ_MEET_ADVISOR.OCCUPATION_SELECT" | translate }}</label>
                <mat-radio-group class="answers-redio-button" formControlName="CurrentOccupation">
                    <mat-radio-button value="Student">{{ "QUIZ_MEET_ADVISOR.OCCUPATION_SELECT-STUDENT_OPTION" | translate }}</mat-radio-button>
                    <mat-radio-button value="Self Employed">{{ "QUIZ_MEET_ADVISOR.OCCUPATION_SELECT-SELF_EMPLOYED_OPTION" | translate }}</mat-radio-button>
                    <mat-radio-button value="Working in a startup">{{ "QUIZ_MEET_ADVISOR.OCCUPATION_SELECT-STARTUP_OPTION" | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="Working in a corporate">{{ "QUIZ_MEET_ADVISOR.OCCUPATION_SELECT-CORPORATE_OPTION" | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="Working for the government">{{ "QUIZ_MEET_ADVISOR.OCCUPATION_SELECT-GOVERMENT_OPTION" | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="Unemployed">{{ "QUIZ_MEET_ADVISOR.OCCUPATION_SELECT-UNEMPLOYED" | translate }}</mat-radio-button>
                </mat-radio-group>
            </section>

            <section class="section-all-qustions">

                <label class="qustions">{{ "QUIZ_MEET_ADVISOR.MARITAL-STATUS_SELECT" | translate }}</label>
                <mat-radio-group formControlName="MaritalStatus">
                    <mat-radio-button value="Single">{{ "QUIZ_MEET_ADVISOR.MARITAL-STATUS_SINGLE_SELECT" | translate }}</mat-radio-button>
                    <mat-radio-button value="Married">{{ "QUIZ_MEET_ADVISOR.MARITAL-STATUS_MARRIED_SELECT" | translate }}</mat-radio-button>
                    <mat-radio-button value="other">{{ "QUIZ_MEET_ADVISOR.MARITAL-STATUS_OTHER_SELECT" | translate }}
                    </mat-radio-button>

                </mat-radio-group>

            </section>
            <section class="section-all-qustions">

                <label class="qustions">{{ "QUIZ_MEET_ADVISOR.LANGUAGE_SELECT" | translate }}*
                </label>
                <mat-form-field appearance="fill">
                    <mat-label>{{ "QUIZ_MEET_ADVISOR.CHOOSE-LANGUAGE" | translate }}</mat-label>
                    <mat-select formControlName="Language">

                        <input type="text" placeholder="Pick the Language" aria-label="Number" matInput
                            [formControl]="myControl" [(ngModel)]="searchTextOne" autocomplete="off"
                            class="input-for-search-Language">


                        <mat-option *ngFor='let language of languages | filter:searchTextOne' [value]="language.name">
                            {{language.name}}
                        </mat-option>

                    </mat-select>
                </mat-form-field>
            </section>
            <mat-form-field class="section-qustion-input" appearance="fill">
                <mat-label>{{ "QUIZ_MEET_ADVISOR.SAVING_CAPACITY" | translate }}</mat-label>
                <input matInput #message maxlength="256" placeholder="What’s your monthly savings capacity?"
                    formControlName="MonthlySavings">
            </mat-form-field>
            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label> {{ "QUIZ_MEET_ADVISOR.MONEY_INVEST-IN-4-YEARS" | translate }} </mat-label>
                <input matInput #message maxlength="256"
                    placeholder="How much money can you invest during the next 4 years?" formControlName="MoneyInvest">
            </mat-form-field>

            <section class="section-all-qustions">
                <label class="qustions">{{ "QUIZ_MEET_ADVISOR.MEETING_FORMAT" | translate }}* </label>
                <mat-radio-group formControlName="FormatMeeting">
                    <mat-radio-button value="Online">{{ "QUIZ_MEET_ADVISOR.MEETING_FORMAT-ONLINE" | translate }}</mat-radio-button>
                    <mat-radio-button value="Offline">{{ "QUIZ_MEET_ADVISOR.MEETING_FORMAT-OFFLINE" | translate }}</mat-radio-button>
                    <mat-radio-button value="It doesn’t matter"> {{ "QUIZ_MEET_ADVISOR.MEETING_FORMAT-ANY" | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </section>
            <section class="section-all-qustions">
                <label class="qustion-seven">{{ "QUIZ_MEET_ADVISOR.LOCATION_SELECT" | translate }}*</label>
                <mat-form-field appearance="fill">
                    <mat-label>{{ "QUIZ_MEET_ADVISOR.CHOOSE_LOCATION" | translate }}</mat-label>
                    <mat-select formControlName="Location">

                        <input type="text" placeholder="Pick the Location" aria-label="Number" matInput
                            [formControl]="myControl" [(ngModel)]="searchTextTwo" autocomplete="off"
                            class="input-for-search-country">


                        <mat-option *ngFor='let country of countries | filter:searchTextTwo' [value]="country.name">
                            {{country.name}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>
            </section>
            <mat-form-field appearance="fill">
                <mat-label>{{ "QUIZ_MEET_ADVISOR.TALKING_ABOUT" | translate }}*</mat-label>
                <input matInput #message maxlength="256"
                    placeholder="What do you want to talk about during the meeting?" formControlName="TalkedMeeting">
            </mat-form-field>
        </section>
        <p *ngIf="warnMessage" class="warn-message">{{ "QUIZ_MEET_ADVISOR.WARN_MSG-FILL_REQUIRED" | translate }} </p>
        <section class="section-button">
            <button class="button-submit" mat-raised-button color="primary">{{ "QUIZ_MEET_ADVISOR.SUBMIT_BTN" | translate }}</button>
        </section>
    </form>
</section>