<section class="section-one">
    <h1>{{ "QUIZ_GIT_START.QUIZ_TITLE" | translate }}</h1>
    <p class="para">{{ "QUIZ_GIT_START.QUIZ_PARAGRAPH_ONE" | translate }}</p>
    <p class="para2">{{ "QUIZ_GIT_START.QUIZ_PARAGRAPH_TWO" | translate }}
    </p>
</section>
<section class="section-two">
    <mat-vertical-stepper [linear]="!isLinear" #stepper>
        <mat-step [stepControl]="EmailFormGroub">
            <form #form="ngForm" [formGroup]="EmailFormGroub" (ngSubmit)="onClickedFillEmail()">
                <ng-template matStepLabel>{{ "QUIZ_GIT_START.EMAIL_FIELD" | translate }}</ng-template>
                <mat-form-field class="box-of-email" appearance="fill">
                    <mat-label>{{ "QUIZ_GIT_START.EMAIL_FIELD-PLACEHOLDER" | translate }}</mat-label>
                    <input matInput #message maxlength="256" placeholder="Your Email...." formControlName="Email"
                        [errorStateMatcher]="matcher">
                    <mat-error *ngIf="EmailFormGroub.hasError('email') && !EmailFormGroub.hasError('required')">
                        {{ "QUIZ_GIT_START.EMAIL-VALIDATE" | translate }}
                    </mat-error>
                    <mat-hint *ngIf="warnMessageForEmail" class="warn-message-for-email">* {{ "QUIZ_GIT_START.WARN_MSG-FILL-EMAIL_REQUIRED" | translate }}
                    </mat-hint>
                </mat-form-field>
                <div>
                    <button mat-button matStepperNext>{{ "QUIZ_GIT_START.NEXT" | translate }}</button>

                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="firstFormGroup">

            <form #form="ngForm" [formGroup]="firstFormGroup" (ngSubmit)="onClickedPartOne()">
                <ng-template matStepLabel>{{ "QUIZ_GIT_START.PART_ONE" | translate }}</ng-template>
                <section class="section-q">
                    <label> <b>1.</b> {{ "QUIZ_GIT_START.PART_ONE_Q_ONE" | translate }}</label>
                    <mat-radio-group class="yes-or-no" formControlName="answerFirstQustion">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>

                </section>
                <section class="section-q">
                    <label> <b>2.</b>{{ "QUIZ_GIT_START.PART_ONE_Q_TWO" | translate }}
                    </label>
                    <mat-radio-group class="yes-or-no" formControlName="answerSecondQustion">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>
                </section>
                <section class="section-q">
                    <label> <b>3.</b> {{ "QUIZ_GIT_START.PART_ONE_Q_THREE" | translate }}
                    </label>
                    <mat-radio-group class="yes-or-no" formControlName="answerThirdQustion">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>
                </section>
                <section class="section-q">
                    <label> <b>4.</b> {{ "QUIZ_GIT_START.PART_ONE_Q_FOUR" | translate }}</label>
                    <mat-radio-group class="yes-or-no" formControlName="answerFourthQustion">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>
                </section>
                <p *ngIf="warnMessagePartOne" class="warn-message">*{{ "QUIZ_GIT_START.PART_ONE_WARN_MSG" | translate }}</p>
                <div>
                    <button mat-button matStepperPrevious>{{ "QUIZ_GIT_START.BACK" | translate }}</button>
                    <button mat-button matStepperNext>{{ "QUIZ_GIT_START.NEXT" | translate }}</button>

                </div>
            </form>
        </mat-step>
        <mat-step *ngIf="showStepOne" [stepControl]="secondFormGroup">
            <form #form="ngForm" [formGroup]="secondFormGroup" (ngSubmit)="onClickedPartTwo()">
                <ng-template matStepLabel>{{ "QUIZ_GIT_START.PART_TWO" | translate }}</ng-template>
                <section class="section-q">
                    <label> <b>1.</b> {{ "QUIZ_GIT_START.PART_TWO_Q_ONE" | translate }}
                    </label>
                    <mat-radio-group class="yes-or-no" formControlName="answerFirstQustionPartTwo">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>

                </section>
                <section class="section-q">
                    <label> <b>2.</b> {{ "QUIZ_GIT_START.PART_TWO_Q_TWO" | translate }}
                    </label>
                    <mat-radio-group class="yes-or-no" formControlName="answerSecondQustionPartTwo">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>
                </section>
                <p *ngIf="warnMessagePartTwo" class="warn-message">*{{ "QUIZ_GIT_START.PART_TWO_WARN_MSG" | translate }}</p>

                <div>
                    <button mat-button matStepperPrevious>{{ "QUIZ_GIT_START.BACK" | translate }}</button>
                    <button mat-button matStepperNext>{{ "QUIZ_GIT_START.NEXT" | translate }}</button>
                </div>
            </form>
        </mat-step>

        <mat-step *ngIf="showStepTwo" [stepControl]="thirdFormGroup">
            <form #form="ngForm" [formGroup]="thirdFormGroup" (ngSubmit)="onClickedPartThree()">
                <ng-template matStepLabel>{{ "QUIZ_GIT_START.PART_THREE" | translate }} </ng-template>
                <section class="section-q">
                    <label> <b>1.</b> {{ "QUIZ_GIT_START.PART_THREE_Q_ONE" | translate }}
                    </label>
                    <mat-radio-group class="yes-or-no" formControlName="answerFirstQustionPartThree">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>
                </section>
                <p *ngIf="warnMessagePartThree" class="warn-message">*{{ "QUIZ_GIT_START.PART_THREE_WARN_MSG" | translate }}</p>

                <div>
                    <button mat-button matStepperPrevious>{{ "QUIZ_GIT_START.BACK" | translate }}</button>
                    <button mat-button matStepperNext>{{ "QUIZ_GIT_START.NEXT" | translate }}</button>
                </div>
            </form>
        </mat-step>
        <mat-step *ngIf="showStepThree" [stepControl]="fourthFormGroup">
            <form [formGroup]="fourthFormGroup" (ngSubmit)="onClickedPartFour()">
                <ng-template matStepLabel>{{ "QUIZ_GIT_START.PART_FOUR" | translate }}</ng-template>
                <section class="section-q">
                    <label> <b>1.</b> {{ "QUIZ_GIT_START.PART_FOUR_Q_ONE" | translate }}
                    </label>
                    <mat-radio-group class="yes-or-no" formControlName="answerFirstQustionPartFour">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>
                </section>
                <p *ngIf="warnMessagePartFour" class="warn-message">*{{ "QUIZ_GIT_START.PART_FOUR_WARN_MSG" | translate }}</p>

                <div>
                    <button mat-button matStepperPrevious>{{ "QUIZ_GIT_START.BACK" | translate }}</button>
                    <button mat-button matStepperNext>{{ "QUIZ_GIT_START.NEXT" | translate }}</button>
                </div>
            </form>
        </mat-step>
        <mat-step *ngIf="showStepFour" [stepControl]="fifthFormGroup">
            <form [formGroup]="fifthFormGroup" (ngSubmit)="onClickedPartFive()">
                <ng-template matStepLabel>{{ "QUIZ_GIT_START.PART_FIVE" | translate }}</ng-template>
                <section class="section-q">
                    <label> <b>1.</b> {{ "QUIZ_GIT_START.PART_FIVE_Q_ONE" | translate }}
                    </label>
                    <mat-radio-group class="yes-or-no" formControlName="answerFirstQustionFive">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button class="no-class" value="No">NO</mat-radio-button>
                    </mat-radio-group>
                </section>
                <p *ngIf="warnMessagePartFive" class="warn-message">*{{ "QUIZ_GIT_START.PART_FIVE_WARN_MSG" | translate }}</p>
                <div>
                    <button mat-button matStepperPrevious>{{ "QUIZ_GIT_START.BACK" | translate }}</button>
                    <button mat-button class="done-button">{{ "QUIZ_GIT_START.NEXT" | translate }}</button>
                </div>
            </form>
        </mat-step>
    </mat-vertical-stepper>
</section>