import { Component, OnInit } from '@angular/core';
import { Languages } from '../languages/languages';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  languagesList = Languages;
  buttonValue: string;
  currentLang: any;
  constructor(public user: UserService, public translate: TranslateService) {
    this.currentLang = JSON.parse(localStorage.getItem('currentLang') || JSON.stringify(Languages[0]));
    this.translate.use(this.currentLang.code);
    this.buttonValue = this.currentLang?.name;
  }

  ngOnInit(): void {
  }
  changeCurrentLang(languages: any): void {
    this.buttonValue = languages.name;
    this.translate.use(languages.code);
    localStorage.setItem('currentLang', JSON.stringify(languages));

    window.location.reload();
  }
  logout(): void {
    this.user.logout();
  }
}
