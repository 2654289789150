import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import EmailAuthProvider = firebase.auth.EmailAuthProvider;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import { TranslateService } from '@ngx-translate/core';



@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css',
    ]
})
export class LoginComponent implements OnInit, OnDestroy {

    ui!: firebaseui.auth.AuthUI;

    constructor(private afAuth: AngularFireAuth,
        private router: Router,
        public translate: TranslateService
    ) {

    }

    ngOnInit() {
        this.afAuth.app.then(app => {
            const uiConfig = {
                signInOptions: [
                    EmailAuthProvider.PROVIDER_ID,
                    GoogleAuthProvider.PROVIDER_ID

                ],
                callbacks: {
                    signInSuccessWithAuthResult: this.onLoginSuccessful.bind(this)
                }
            };
            this.ui = new firebaseui.auth.AuthUI(app.auth());
            this.ui.start('#firebaseui-auth-container', uiConfig);
            this.ui.disableAutoSignIn()
        })
    }

    ngOnDestroy() {
        this.ui.delete();
    }
    onLoginSuccessful() {


        this.router.navigateByUrl('/home');
        return true;
    }
}


