import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { AllQuestion } from './allQuestions';
import { StoreAnsweQuizsService } from '../../services/store-answe-quizs.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-quiz-git-start',
  templateUrl: './quiz-git-start.component.html',
  styleUrls: ['./quiz-git-start.component.css']
})
export class QuizGitStartComponent implements OnInit {
  isLinear = false;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  thirdFormGroup!: FormGroup;
  fourthFormGroup!: FormGroup;
  fifthFormGroup!: FormGroup;
  EmailFormGroub!: FormGroup;
  warnMessagePartOne = false;
  warnMessagePartTwo = false;
  warnMessagePartThree = false;
  warnMessagePartFour = false;
  warnMessagePartFive = false;
  lastMessage = false;
  warnMessageForEmail = false;
  showStepOne = false;
  showStepTwo = false;
  showStepThree = false;
  showStepFour = false;
  matcher = new MyErrorStateMatcher();
  arrayQuiz: any = [];
  allQuestions = AllQuestion;
  // tslint:disable-next-line:variable-name
  constructor(private _formBuilder: FormBuilder, private router: Router,
              private storeAnsweQuizsService: StoreAnsweQuizsService,
              public translate: TranslateService

  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      answerFirstQustion: ['', Validators.required],
      answerSecondQustion: ['', Validators.required],
      answerThirdQustion: ['', Validators.required],
      answerFourthQustion: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      answerFirstQustionPartTwo: ['', Validators.required],
      answerSecondQustionPartTwo: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      answerFirstQustionPartThree: ['', Validators.required],
    });
    this.fourthFormGroup = this._formBuilder.group({
      answerFirstQustionPartFour: ['', Validators.required],

    });
    this.fifthFormGroup = this._formBuilder.group({
      answerFirstQustionFive: ['', Validators.required],

    });
    this.EmailFormGroub = this._formBuilder.group({
      Email: ['', [Validators.required, Validators.email]]
    });


  }
  onClickedFillEmail(): void {
    const email = this.EmailFormGroub.value.Email;
    if (email) {
      this.warnMessageForEmail = false;
      return;
    } else {
      this.warnMessageForEmail = true;
    }
  }

  onClickedPartOne(): void {
    const firstAnswerPart1 = this.firstFormGroup.value.answerFirstQustion;
    const secondAnswerPart1 = this.firstFormGroup.value.answerSecondQustion;
    const thirdAnswerPart1 = this.firstFormGroup.value.answerThirdQustion;
    const fourthAnswerPart1 = this.firstFormGroup.value.answerFourthQustion;
    const question1 = this.allQuestions[0].questionOne;
    const question2 = this.allQuestions[1].questionTwo;
    const question3 = this.allQuestions[2].questionThree;
    const question4 = this.allQuestions[3].questionFour;

    this.arrayQuiz.push({ q: question1, a: firstAnswerPart1 });
    this.arrayQuiz.push({ q: question2, a: secondAnswerPart1 });
    this.arrayQuiz.push({ q: question3, a: thirdAnswerPart1 });
    this.arrayQuiz.push({ q: question4, a: fourthAnswerPart1 });

    if (firstAnswerPart1 && secondAnswerPart1 && thirdAnswerPart1 && fourthAnswerPart1) {
      this.warnMessagePartOne = false;

      if (firstAnswerPart1 === 'Yes' && secondAnswerPart1 === 'Yes' && thirdAnswerPart1 === 'Yes' && fourthAnswerPart1 === 'Yes') {
        this.showStepOne = true;
        return;
      }
      else if (firstAnswerPart1 === 'Yes' && secondAnswerPart1 === 'Yes' && thirdAnswerPart1 === 'Yes' && fourthAnswerPart1 === 'No') {
        this.showStepOne = true;
        return;
      }
      else if (firstAnswerPart1 === 'Yes' && secondAnswerPart1 === 'Yes' && thirdAnswerPart1 === 'No' && fourthAnswerPart1 === 'Yes') {
        this.showStepOne = true;
        return;
      }
      else if (firstAnswerPart1 === 'Yes' && secondAnswerPart1 === 'No' && thirdAnswerPart1 === 'Yes' && fourthAnswerPart1 === 'Yes') {
        this.showStepOne = true;
        return;
      }
      else if (firstAnswerPart1 === 'No' && secondAnswerPart1 === 'Yes' && thirdAnswerPart1 === 'Yes' && fourthAnswerPart1 === 'Yes') {
        this.showStepOne = true;
        return;
      }
      else {
        const email = this.EmailFormGroub.value.Email;
        const quiz = this.arrayQuiz;
        const emailAndQuiz = { email, quiz };
        this.storeAnsweQuizsService.storeQuiz(emailAndQuiz).subscribe(res => {
          this.router.navigate(['/banks-and-savings-rules']);
        });

      }

    }
    else {
      this.warnMessagePartOne = true;
    }
  }

  onClickedPartTwo(): void {
    const firstAnswerpart2 = this.secondFormGroup.value.answerFirstQustionPartTwo;
    const secondAnswerpart2 = this.secondFormGroup.value.answerSecondQustionPartTwo;
    const question5 = this.allQuestions[4].questionFive;
    const question6 = this.allQuestions[5].questionsix;

    this.arrayQuiz.push({ q: question5, a: firstAnswerpart2 });
    this.arrayQuiz.push({ q: question6, a: secondAnswerpart2 });

    if (firstAnswerpart2 && secondAnswerpart2) {
      this.warnMessagePartTwo = false;

      if (firstAnswerpart2 === 'Yes' && secondAnswerpart2 === 'Yes') {
        this.showStepTwo = true;
        return;
      }
      else {
        const email = this.EmailFormGroub.value.Email;
        const quiz = this.arrayQuiz;
        const emailAndQuiz = { email, quiz };
        this.storeAnsweQuizsService.storeQuiz(emailAndQuiz).subscribe(res => {
          this.router.navigate(['/pension-fund']);
        });
      }

    }
    else {
      this.warnMessagePartTwo = true;

    }


  }
  onClickedPartThree(): void {
    const firstAnswerPart3 = this.thirdFormGroup.value.answerFirstQustionPartThree;
    const question7 = this.allQuestions[6].questionSeven;
    this.arrayQuiz.push({ q: question7, a: firstAnswerPart3 });
    if (firstAnswerPart3) {
      this.warnMessagePartThree = false;

      if (firstAnswerPart3 === 'Yes') {
        this.showStepThree = true;
        return;
      }
      else if (firstAnswerPart3 === 'No') {
        const email = this.EmailFormGroub.value.Email;
        const quiz = this.arrayQuiz;
        const emailAndQuiz = { email, quiz };
        this.storeAnsweQuizsService.storeQuiz(emailAndQuiz).subscribe(res => {
          this.router.navigate(['/investments']);
        });
      }

    }
    else {
      this.warnMessagePartThree = true;
    }


  }
  onClickedPartFour(): void {
    const firstAnswerPart4 = this.fourthFormGroup.value.answerFirstQustionPartFour;
    const question8 = this.allQuestions[7].questionEight;

    this.arrayQuiz.push({ q: question8, a: firstAnswerPart4 });

    if (firstAnswerPart4) {
      this.warnMessagePartFour = false;

      if (firstAnswerPart4 === 'Yes') {
        this.showStepFour = true;
        return;
      }
      else {
        const email = this.EmailFormGroub.value.Email;
        const quiz = this.arrayQuiz;
        const emailAndQuiz = { email, quiz };
        this.storeAnsweQuizsService.storeQuiz(emailAndQuiz).subscribe(res => {
          this.router.navigate(['/alternative-investments']);
        });
      }

    }
    else {
      this.warnMessagePartFour = true;
    }


  }
  onClickedPartFive(): void {
    const firstAnswerPart5 = this.fifthFormGroup.value.answerFirstQustionFive;
    const question9 = this.allQuestions[8].questionNine;

    this.arrayQuiz.push({ q: question9, a: firstAnswerPart5 });

    if (firstAnswerPart5) {
      this.warnMessagePartFive = false;
      if (firstAnswerPart5 === 'Yes') {
        this.router.navigate(['/last-step']);
        const email = this.EmailFormGroub.value.Email;
        const quiz = this.arrayQuiz;
        const emailAndQuiz = { email, quiz };
        this.storeAnsweQuizsService.storeQuiz(emailAndQuiz);
      }
      else {
        const email = this.EmailFormGroub.value.Email;
        const quiz = this.arrayQuiz;
        const emailAndQuiz = { email, quiz };
        this.storeAnsweQuizsService.storeQuiz(emailAndQuiz).subscribe(res => {
          this.router.navigate(['/impact-your-money']);
        });
      }

    }
    else {
      this.warnMessagePartFive = true;
    }

  }
}
