import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { from, Observable } from 'rxjs';
import { Alternative } from '../model/alternative';
import { convertSnaps } from './db-utils';
import { concatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlternativesService {
  constructor(private db: AngularFirestore,

  ) { }


  findAlternativeByUrl(alternativeName: any): Observable<any | null> {
    return this.db.collection('alternatives',
      ref => ref.where('name', '==', alternativeName))
      .get().pipe(map(result => {
        const alternatievs = convertSnaps<Alternative>(result);
        return alternatievs.length === 1 ? alternatievs[0] : null;

      }
      ));
  }



  deleteAlternative(alternativeId: string): Observable<void> {
    return from(this.db.doc(`alternatives/${alternativeId}`).delete());
  }
  AllAlternatives(): Observable<Alternative[]> {
    return this.db.collection('alternatives').get().pipe(map((result) => convertSnaps<Alternative>(result)));

  }

  updataAlternativesById(alternativeId: string, changes: Partial<Alternative>): Observable<void> {
    return from(this.db.doc(`alternatives/${alternativeId}`).update(changes));

  }
  createAlternative(newAltrnative: Partial<Alternative>, alternativeId?: string): Observable<any> {
    return this.db.collection('alternatives',
      ref => ref.orderBy('seqNo', 'desc').limit(1))
      .get()
      .pipe(concatMap(result => {
        const alternatives = convertSnaps<Alternative>(result);
        const lastAlternativSeqNo = alternatives[0]?.seqNo ?? 0;
        const alternative = {
          ...newAltrnative,
          seqNo: lastAlternativSeqNo + 1
        };
        let save$: Observable<any>;
        // tslint:disable-next-line:prefer-conditional-expression
        if (alternativeId) {
          save$ = from(this.db.doc(`alternatives/${alternativeId}`).set(alternative));
        }
        else {
          save$ = from(this.db.collection('alternatives').add(alternative));

        }

        return save$
          .pipe(map(res => {
            return {
              id: alternativeId ?? res.id,
              ...alternative
            };
          }));
      })
      );

  }

  loadAlternativesByfilter(
    country: string,
    overallCategory: string,
    limit: number
  ): Observable<Alternative[]> {
    return this.db
      .collection('alternatives', (ref) =>
        ref
          .where('country', 'array-contains', country)
          .where('overallCategory', '==', overallCategory)
          .limit(limit)
          .orderBy('seqNo')
      )
      .get()
      .pipe(map((result) => convertSnaps<Alternative>(result)));
  }



}
