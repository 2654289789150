<section class="section-one">
    <img class="alternative-investments" src="assets/alternativeInvestments.png">
    <div class="continer">
        <h1>Alternative investments</h1>
        <p>You’ve invested your money, but you want/need to diversify? We’ve got you covered. Within sustainable
            investing
            you can have the impact you want at a local or global level.<a routerLink="/alternatives/investments"><b>
                    And
                    it’s all here</b></a> And it’s all here. So take the time to go through
            the ways of investing available in your country and choose what is the next impact you’ll make with your
            money
            today! </p>
    </div>
</section>