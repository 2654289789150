import { Component, OnInit, Inject } from '@angular/core';
import { AddAdvisorsService } from '../../services/add-advisors.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Advisors } from '../../model/advisors';

@Component({
  selector: 'app-edit-advisor-dialg',
  templateUrl: './edit-advisor-dialg.component.html',
  styleUrls: ['./edit-advisor-dialg.component.css']
})
export class EditAdvisorDialgComponent implements OnInit {
  form: FormGroup;
  advisor: Advisors;

  constructor(private addAdvisorsService: AddAdvisorsService, private dialogRef: MatDialogRef<EditAdvisorDialgComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) Advisor: Advisors) {
    this.advisor = Advisor;
    this.form = this.fb.group({
      name: [Advisor.name],
      email: [Advisor.email],
      language: [Advisor.language],
      location: [Advisor.location],
      link: [Advisor.link],


    });
  }

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close();

  }
  save(): void {
    const changes = this.form.value;
    this.addAdvisorsService.updataAdvisorsById(this.advisor.id, changes).subscribe(() => {
      this.dialogRef.close(changes);
    });
  }

}
