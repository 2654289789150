<section class="main-container">
  <h2>
    {{ "USER_SUGGESTIONS.SECTION_ONE_PARAGRAPH_ONE" | translate }}<br />
    {{ "USER_SUGGESTIONS.SECTION_ONE_PARAGRAPH_TWO" | translate }}
  </h2>
  <section class="card-section">
    <mat-card class="alternative-card mat-elevation-z10">
      <mat-card-content>
        <h3>

          {{ "USER_SUGGESTIONS.INDIVIDUAL-CARD_PARAGRAPH_ONE" | translate }} <br />

        </h3>
      </mat-card-content>

      <mat-card-actions class="alternative-actions">
        <button mat-button class="Add-Alternative" routerLink="/user-add-alternative">
          {{ "USER_SUGGESTIONS.INDIVIDUAL-CARD_BUTTON" | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card class="initiative-card mat-elevation-z10">
      <mat-card-content>
        <h3>
          {{ "USER_SUGGESTIONS.PARTNER-CARD_PARAGRAPH_ONE" | translate }} <br />

        </h3>
      </mat-card-content>

      <mat-card-actions class="alternative-actions">
        <button mat-button class="Add-Initiative" routerLink="/add-initiatives">{{
          "USER_SUGGESTIONS.PARTNER-CARD_BUTTON" | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </section>
</section>