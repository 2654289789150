import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Alternative } from '../../model/alternative';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditAlternativeDialogComponent } from '../edit-alternative-dialog/edit-alternative-dialog.component';
import { EditUserAlternativesDialogComponent } from '../edit-user-alternatives-dialog/edit-user-alternatives-dialog.component';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AlternativesService } from '../../services/alternatives.service';
import { UserService } from '../../services/user.service';
import { UserAlternativesService } from '../../services/user-alternatives.service';
import { Languages } from '../languages/languages';
import { TranslateService } from '@ngx-translate/core';
import { AddLanguageDialogComponent } from '../add-language-dialog/add-language-dialog.component';
@Component({
  selector: 'app-alternatives-card-list',
  templateUrl: './alternatives-card-list.component.html',
  styleUrls: ['./alternatives-card-list.component.css'],
})
export class AlternativesCardListComponent implements OnInit {
  showMessage = false;
  errorMessage = '';

  @Input() alternatives!: Alternative[] | null;
  @Input() isuser = false;

  @Output() alternativeEdited = new EventEmitter();

  @Output()
  alternativeDeleted = new EventEmitter<Alternative>();
  @Output()
  alternativeApproved = new EventEmitter<Alternative>();

  currentLang: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private alternativesService: AlternativesService,
    public user: UserService,
    private userAlternativesService: UserAlternativesService,
    public translate: TranslateService
  ) {
    this.currentLang = JSON.parse(
      localStorage.getItem('currentLang') || JSON.stringify(Languages[0])
    );
  }

  ngOnInit(): void { }

  viweDetail(alternativeCard: Alternative): void {
    if (alternativeCard.overallCategory === 'Pension Fund') {
      this.router.navigate(['/quiz-meet-financial-advisor']);
    } else {
      if (this.isuser) {
        this.router.navigate(['/user-alternative', alternativeCard.name]);
      } else {
        this.router.navigate(['/alternative', alternativeCard.name]);
      }
    }
  }
  editAlternative(alternative: Alternative): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '400px';
    dialogConfig.data = alternative;
    if (this.isuser === false) {
      this.dialog
        .open(EditAlternativeDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((val) => {
          if (val) {
            this.alternativeEdited.emit();
          }
        });
    } else if (this.isuser === true) {
      this.dialog
        .open(EditUserAlternativesDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((val) => {
          if (val) {
            this.alternativeEdited.emit();
          }
        });
    }
  }
  onDeleteAlternative(alternative: Alternative): void {
    if (!this.isuser) {
      this.alternativesService
        .deleteAlternative(alternative.id)
        .pipe(
          tap(() => {
            this.alternativeDeleted.emit(alternative);
          }),
          catchError((err) => {
            alert('could not Delete alternative');
            return throwError(err);
          })
        )
        .subscribe();
    } else if (this.isuser) {
      this.userAlternativesService
        .deleteUserAlternative(alternative.id)
        .pipe(
          tap(() => {
            this.alternativeDeleted.emit(alternative);
          }),
          catchError((err) => {
            alert('could not Delete alternative');
            return throwError(err);
          })
        )
        .subscribe();
    }
    else if (this.isuser) {
      this.userAlternativesService.deleteUserAlternative(alternative.id).pipe(tap(() => {
        this.alternativeDeleted.emit(alternative);
      }),
        catchError((err) => {
          alert('could not Delete alternative');
          return throwError(err);
        })

      ).subscribe();
    }
  }
  onCheckBox(alternative: Alternative): void {

    this.alternativesService.AllAlternatives().subscribe((res: Alternative[]) => {
      const isExist = res.find(ele => ele.name === alternative.name);
      if (!!!isExist) {
        this.showMessage = false;
        this.alternativesService.createAlternative(alternative, alternative.id)
          .pipe(tap(() => {
            this.alternativeApproved.emit(alternative);
            this.onDeleteAlternative(alternative);
          }),
            catchError(err => {
              return throwError(err);
            })
          ).subscribe();
      }
      else {
        this.showMessage = true;
        this.errorMessage = 'Title is already used';
      }
    });
  }
  onAddLanguage(alternative: Alternative): void {
    const dialogConfigAddLangage = new MatDialogConfig();
    dialogConfigAddLangage.disableClose = true;
    dialogConfigAddLangage.autoFocus = true;
    dialogConfigAddLangage.minWidth = '400px';
    dialogConfigAddLangage.data = alternative;
    this.dialog
      .open(AddLanguageDialogComponent, dialogConfigAddLangage)
      .afterClosed()
      .subscribe();

  }


}



