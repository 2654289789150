<section>
  <img src="assets/hand-with-planet.jpeg" class="hand-with-planet" />
  <p class="title-on-home-page">{{ "HOME.TITLE" | translate }}</p>
  <p class="small-paragraph">
    {{ "HOME.SECTION_ONE_TEXT" | translate }}
  </p>
</section>
<section class="container-section1">
  <div class="div-container-title-and-3buttons">
    <p class="title-for-discover-your-way-to-act">
      {{ "HOME.ACT_TEXT" | translate }}
    </p>
    <div class="div-contain-3buttons">
      <a href="https://www.youtube.com/watch?v=RpwKkMuCExQ" target="_blank">
        <button mat-raised-button color="primary" class="buttons-for-intro-video-alternatives-impact-money">
          {{ "HOME.ACT-BUTTON-ONE" | translate }}
        </button>
      </a>
      <button mat-raised-button color="primary" class="buttons-for-intro-video-alternatives-impact-money"
        routerLink="/alternatives">
        {{ "HOME.ACT-BUTTON-TWO" | translate }}
      </button>
      <button mat-raised-button color="primary" class="buttons-for-intro-video-alternatives-impact-money"
        routerLink="/initiatives">
        {{ "HOME.ACT-BUTTON-THREE" | translate }}
      </button>
    </div>
  </div>
</section>
<section class="container-section2">
  <div class="div-container-photo-title-paragraph-button">
    <img class="photo-for-girl-with-earth" src="assets/girl-with-earth.jpeg" />
    <div class="div-contain-title-and-paragraph">
      <p class="title-for-make-your-money-sustainable">
        {{ "HOME.SUSTAINABLE_TEXT" | translate }}
      </p>
      <p class="larg-paragraph">
        {{ "HOME.SUSTAINABLE_PARAGRAPH" | translate }}
      </p>
      <button mat-raised-button color="primary" class="button-for-start-today" routerLink="/alternatives">
        {{ "HOME.START_TODAY" | translate }}
        </button>
    </div>
  </div>
</section>
<section class="container-section3">
  <p class="titles-our-users-love-us-and-financial-experts-trust-us">
    {{ "HOME.USER_LOVE_US" | translate }}
  </p>
  <p class="two-larg-paragraphs">
    {{ "HOME.USER_STORY" | translate }}
    <i> {{ "HOME.USER_STORY-NAME" | translate }}</i>
  </p>
  <p class="titles-our-users-love-us-and-financial-experts-trust-us">
    {{ "HOME.FINANCIAL_TRUST" | translate }}
  </p>
  <p class="two-larg-paragraphs">
    {{ "HOME.FINANCIAL_STORY" | translate }} <br />
    <i> {{ "HOME.FINANCIAL_STORY-NAME" | translate }}</i>
  </p>
</section>