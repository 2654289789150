<section class="form-section">
    <h1 class="title-of-add-advisor">Add initiative</h1>
    <form #form="ngForm" [formGroup]="AddInitiativeFormGroub" class="form-add-advisor" (ngSubmit)="onAddInitiative()">
        <section class="form-section-two">
            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label>Name of Initiative*</mat-label>
                <input matInput #message maxlength="256" placeholder="Initiative name" formControlName="NameInitiative">
            </mat-form-field>

            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label>Link of initiative*</mat-label>
                <input matInput #message maxlength="256" placeholder="Link of initiative:"
                    formControlName="LinkInitiative">
            </mat-form-field>
            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label>Short description of initiative</mat-label>
                <input matInput #message maxlength="256" placeholder="short description..."
                    formControlName="ShortDescription">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Country of initiative*</mat-label>
                <mat-select formControlName="Country" multiple>

                    <input type="text" placeholder="Pick the Location" aria-label="Number" matInput
                        [formControl]="myControl" [(ngModel)]="searchText" autocomplete="off"
                        class="input-for-search-country">


                    <mat-option *ngFor='let country of countries | filter:searchText' [value]="country.name">
                        {{country.name}}
                    </mat-option>
                </mat-select>

            </mat-form-field>
        </section>
        <p *ngIf="warnMessage" class="warn-message">*Please fill out the important elements</p>
        <section class="section-button">
            <button class="add-button" mat-raised-button color="primary">Add initiative</button>
        </section>

    </form>
</section>