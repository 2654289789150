import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'excerpt' })
export class Excerpt implements PipeTransform {
    transform(content: string): any {
        const postSummary = content.replace(/(<([^>]+)>)/ig, '');
        if (postSummary.length > 300) {
            return postSummary.substr(0, 200) + ' [...]';
        } else {
            return postSummary;
        }
    }
}
