import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Initiative } from '../model/initiative';
import { convertSnaps } from './db-utils';
@Injectable({
  providedIn: 'root'
})
export class InitiativeService {

  constructor(private db: AngularFirestore) { }
  createInitiative(newInitative: Partial<Initiative>, initativeId?: string): Observable<any> {
    return this.db.collection('initatives',
      ref => ref.orderBy('seqNo', 'desc').limit(1))
      .get()
      .pipe(concatMap(result => {
        const initatives = convertSnaps<Initiative>(result);
        const lastinitativeSeqNo = initatives[0]?.seqNo ?? 0;
        const initative = {
          ...newInitative,
          seqNo: lastinitativeSeqNo + 1
        };
        let save$: Observable<any>;
        // tslint:disable-next-line:prefer-conditional-expression
        if (initativeId) {
          save$ = from(this.db.doc(`initatives/${initativeId}`).set(initative));
        }
        else {
          save$ = from(this.db.collection('initatives').add(initative));

        }

        return save$
          .pipe(map(res => {
            return {
              id: initativeId ?? res.id,
              ...initative
            };
          }));
      })
      );

  }
  AllInitative(): Observable<Initiative[]> {
    return this.db.collection('initatives').get().pipe(map((result) => convertSnaps<Initiative>(result)));

  }
  deleteInitative(initativeId: string): Observable<void> {
    return from(this.db.doc(`initatives/${initativeId}`).delete());
  }
  createInitiativeAdmin(newInitative: Partial<Initiative>, initativeId?: string): Observable<any> {
    return this.db.collection('initativesAdmin',
      ref => ref.orderBy('seqNo', 'desc').limit(1))
      .get()
      .pipe(concatMap(result => {
        const initatives = convertSnaps<Initiative>(result);
        const lastinitativeSeqNo = initatives[0]?.seqNo ?? 0;
        const initative = {
          ...newInitative,
          seqNo: lastinitativeSeqNo + 1
        };
        let save$: Observable<any>;
        // tslint:disable-next-line:prefer-conditional-expression
        if (initativeId) {
          save$ = from(this.db.doc(`initativesAdmin/${initativeId}`).set(initative));
        }
        else {
          save$ = from(this.db.collection('initativesAdmin').add(initative));

        }

        return save$
          .pipe(map(res => {
            return {
              id: initativeId ?? res.id,
              ...initative
            };
          }));
      })
      );

  }
  AllInitativeAdmin(): Observable<Initiative[]> {
    return this.db.collection('initativesAdmin').get().pipe(map((result) => convertSnaps<Initiative>(result)));

  }
  deleteInitativeAdmin(initativeId: string): Observable<void> {
    return from(this.db.doc(`initativesAdmin/${initativeId}`).delete());

  }
  updataInitativeAdminById(initativeId: string, changes: Partial<Initiative>): Observable<void> {
    return from(this.db.doc(`initativesAdmin/${initativeId}`).update(changes));

  }
  loadInitativesByfilter(
    country: string,
    limit: number
  ): Observable<Initiative[]> {
    return this.db
      .collection('initativesAdmin', (ref) =>
        ref
          .where('country', 'array-contains', country)
          .limit(limit)
          .orderBy('seqNo')
      )
      .get()
      .pipe(map((result) => convertSnaps<Initiative>(result)));
  }

}
