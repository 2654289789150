import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { concatMap, map } from 'rxjs/operators';
import { UserRoles } from '../model/user-roles';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { Advisors } from '../model/advisors';
import { convertSnaps } from './db-utils';


@Injectable({
  providedIn: 'root',
})
export class UserService {

  defaultProfilePic = 'https://www.pngkey.com/png/detail/115-1150152_default-profile-picture-avatar-png-green.png';
  isLoggedIn$: Observable<any>;
  isLoggedOut$: Observable<boolean>;
  pictureUrl$: Observable<any>;
  roles$: Observable<UserRoles>;
  user$: Observable<any>;
  constructor(private afAuth: AngularFireAuth,
              private db: AngularFirestore,
              private router: Router) {

    this.user$ = afAuth.authState.pipe(map(user => user ? user : null));

    this.isLoggedIn$ = afAuth.authState.pipe(map(user => !!user));
    this.isLoggedOut$ = this.isLoggedIn$.pipe(map(loggedIn => !loggedIn));

    this.pictureUrl$ = afAuth.authState.pipe(map(user => user ? user.photoURL ? user.photoURL : this.defaultProfilePic : null));

    this.roles$ = this.afAuth.idTokenResult
      .pipe(
        map(token => (token?.claims as any) ?? { admin: false })
      );

  }

  createUserTrack(newTrack: Partial<any>, userTrackID?: string, trackCategory?: string, trackCategoryName?: string): Observable<any> {
    let userTrackId = userTrackID;
    if (userTrackID === undefined) { userTrackId = 'unknown'; }
    return this.db.collection('userTrack',
      ref => ref
        .where('userEmail', '==', userTrackId)
        .limit(1))
      .get()
      .pipe(concatMap(result => {
        const userTrack = convertSnaps<any>(result);
        const lastClickNum = userTrack[0]?.[trackCategory]?.[trackCategoryName] ?? 0;

        const trackData = {
          ...newTrack,
          [trackCategory]: { [trackCategoryName]: lastClickNum + 1 }

        };
        let save$: Observable<any>;
        // tslint:disable-next-line:prefer-conditional-expression
        if (userTrackId) {
          save$ = from(this.db.doc(`userTrack/${userTrackId}`).set(trackData, { merge: true }));
        }
        else {
          save$ = from(this.db.collection('userTrack').add(trackData));

        }

        return save$
          .pipe(map(res => {
            return {
              id: userTrackId ?? res.id,
              ...trackData
            };
          }));
      })
      );

  }

  loadUserTrackByEmail(
    email: string,
  ): Observable<any[]> {
    return this.db
      .collection('userTrack', (ref) =>
        ref
          .where('userEmail', '==', email)
      )
      .get()
      .pipe(map((result) => convertSnaps<any>(result)));
  }


  logout() {
    this.afAuth.signOut();
    this.router.navigateByUrl('/login');
  }


}
