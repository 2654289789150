import { Component, OnInit } from '@angular/core';
import { Alternative } from '../../model/alternative';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Languages } from '../../components/languages/languages';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-alternative-detiles-page',
  templateUrl: './alternative-detiles-page.component.html',
  styleUrls: ['./alternative-detiles-page.component.css'],
})
export class AlternativeDetilesPageComponent implements OnInit {
  alternativeDetails!: Alternative;
  target = '';
  href = '';
  currentLang: any;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public user: UserService,
    public translate: TranslateService
  ) {
    this.currentLang = JSON.parse(
      localStorage.getItem('currentLang') || JSON.stringify(Languages[0])
    );
  }

  ngOnInit(): void {
    this.onDetailsAlternative();
  }
  onDetailsAlternative(): void {
    this.alternativeDetails = this.route.snapshot.data.alternative;
  }
  onBack(): void {
    this.location.back();
  }
  onClickWbsite(user): any {
    const alternativeName = this.alternativeDetails.name.replace(/\s/g, '');

    const trackData = {
      userEmail: user.email,
      userName: user?.displayName,
    };
    this.user
      .createUserTrack(trackData, user.email, 'alternative', alternativeName)
      .subscribe((val: any) => { });

    const category = this.alternativeDetails.overallCategory;
    const website = this.alternativeDetails.Website;
    const navigate1 = 'quiz-meet-financial-advisor';
    if (category === 'Pension Fund') {
      return (this.href = navigate1);
    } else {
      this.target = '_blank';
      return (this.href = website);
    }
  }
}
