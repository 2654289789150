<mat-toolbar-row class="footer">

    <section class="section-one">
        <h2>2021 © mymoneymyplanet</h2>
        <p>
            {{ "FOOTER.PARAGRAPH_ONE" | translate }}
            
        </p>
        <p>
            {{ "FOOTER.PARAGRAPH_TWO" | translate }}
            
        </p>
        <p>
            {{ "FOOTER.PARAGRAPH_THREE" | translate }}
            
        </p>
    </section>
    <section class="section-links">
        <div class="anchor-for-mymoneymyplanet-logo">
            <a routerLink="/alternatives">
                <span>Act today</span>
            </a>
        </div>

        <div class="anchor-for-mymoneymyplanet-logo">
            <a routerLink="/user-add-alternative">
                <span> {{ "FOOTER.ADD_ALTERNATIVE" | translate }}</span>
            </a>
        </div>

        <div class="anchor-for-mymoneymyplanet-logo">
            <a routerLink="/add-initiatives">
                <span>{{ "FOOTER.ADD_INITIATIVE" | translate }}</span>
            </a>
        </div>
        <div class="anchor-for-mymoneymyplanet-logo">
            <a routerLink="/about-us">
                <span>{{ "FOOTER.ABOUT_US" | translate }}</span>
            </a>
        </div>

        <div class="anchor-for-mymoneymyplanet-logo">
            <a routerLink="/quiz-meet-financial-advisor">
                <span> {{ "FOOTER.CONTACT_US" | translate }}</span>
            </a>
        </div>


    </section>

</mat-toolbar-row>