import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { AllQuestion } from './allQuestions';
import { StoreAnsweQuizsService } from '../../services/store-answe-quizs.service';
import { Countries } from '../../components/add-alternative/countries';
import { Languages } from '../add-advisor/Languages';
import { TranslateService } from '@ngx-translate/core';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-quiz-meet-financial-advisor',
  templateUrl: './quiz-meet-financial-advisor.component.html',
  styleUrls: ['./quiz-meet-financial-advisor.component.css'],
})
export class QuizMeetFinancialAdvisorComponent implements OnInit {
  QuizMeerFinancialFormGroub!: FormGroup;
  matcher = new MyErrorStateMatcher();
  warnMessage = false;
  arrayOfQuiz: any = [];
  AllQuestion = AllQuestion;
  countries = Countries;
  myControl = new FormControl();
  searchTextOne = '';
  searchTextTwo = '';
  languages = Languages;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private storeAnsweQuizsService: StoreAnsweQuizsService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.QuizMeerFinancialFormGroub = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Age: [''],
      CurrentOccupation: [''],
      MaritalStatus: [''],
      Language: ['', Validators.required],
      MonthlySavings: [''],
      MoneyInvest: [''],
      FormatMeeting: ['', Validators.required],
      Location: ['', Validators.required],
      TalkedMeeting: ['', Validators.required],
    });
  }
  onSubmitQuiz(): void {
    const userName = this.QuizMeerFinancialFormGroub.value.Name;
    const userEmail = this.QuizMeerFinancialFormGroub.value.Email;
    const userAge = this.QuizMeerFinancialFormGroub.value.Age;
    const userCurrentOccupation =
      this.QuizMeerFinancialFormGroub.value.CurrentOccupation;
    const userMaritalStatus =
      this.QuizMeerFinancialFormGroub.value.MaritalStatus;
    const chooseLanguage = this.QuizMeerFinancialFormGroub.value.Language;
    const userMonthlySavings =
      this.QuizMeerFinancialFormGroub.value.MonthlySavings;
    const userMoneyInvest = this.QuizMeerFinancialFormGroub.value.MoneyInvest;
    const formatMeeting = this.QuizMeerFinancialFormGroub.value.FormatMeeting;
    const userLocation = this.QuizMeerFinancialFormGroub.value.Location;
    const userTalkedMeeting =
      this.QuizMeerFinancialFormGroub.value.TalkedMeeting;

    const question1 = this.AllQuestion[0].questionOne;
    const question2 = this.AllQuestion[1].questionTwo;
    const question3 = this.AllQuestion[2].questionThree;
    const question4 = this.AllQuestion[3].questionFour;
    const question5 = this.AllQuestion[4].questionFive;
    const question6 = this.AllQuestion[5].questionsix;
    const question7 = this.AllQuestion[6].questionSeven;
    const question8 = this.AllQuestion[7].questionEight;
    const question9 = this.AllQuestion[8].questionNine;
    const question10 = this.AllQuestion[9].questionTen;
    const question11 = this.AllQuestion[10].questionEleven;

    this.arrayOfQuiz.push({ q: question1, a: userName });
    this.arrayOfQuiz.push({ q: question2, a: userEmail });
    this.arrayOfQuiz.push({ q: question3, a: userAge });
    this.arrayOfQuiz.push({ q: question4, a: userCurrentOccupation });
    this.arrayOfQuiz.push({ q: question5, a: userMaritalStatus });
    this.arrayOfQuiz.push({ q: question6, a: chooseLanguage });
    this.arrayOfQuiz.push({ q: question7, a: userMonthlySavings });
    this.arrayOfQuiz.push({ q: question8, a: userMoneyInvest });
    this.arrayOfQuiz.push({ q: question9, a: formatMeeting });
    this.arrayOfQuiz.push({ q: question10, a: userLocation });
    this.arrayOfQuiz.push({ q: question11, a: userTalkedMeeting });

    if (
      userName &&
      userEmail &&
      chooseLanguage &&
      formatMeeting &&
      userLocation &&
      userTalkedMeeting
    ) {
      this.warnMessage = false;
      this.storeAnsweQuizsService
        .storeQuizMeetFinancialAdvisor(this.arrayOfQuiz)
        .subscribe((res) => {});
      this.router.navigate([
        '/meet-financial-advisor',
        chooseLanguage,
        userLocation,
      ]);
    } else {
      this.warnMessage = true;
    }
  }
}
