<section class="form-section">
    <h1 class="title-of-add-advisor">Add Financial Advisor</h1>
    <form #form="ngForm" [formGroup]="AddAdvisorFormGroub" class="form-add-advisor" (ngSubmit)="onAddAdvior()">
        <section class="form-section-two">
            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label>Name of financial advisor:</mat-label>
                <input matInput #message maxlength="256" placeholder="financial advisor name"
                    formControlName="NameFinancialAdvisor">
            </mat-form-field>

            <mat-form-field class="box-of-email" appearance="fill">
                <mat-label>Email of financial advisor:</mat-label>
                <input matInput #message maxlength="256" placeholder="Your Email...."
                    formControlName="EmailFinancialAdvisor" [errorStateMatcher]="matcher">
                <mat-error *ngIf="AddAdvisorFormGroub.hasError('email') && !AddAdvisorFormGroub.hasError('required')">
                    Please enter a valid email address
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Language of financial advisor:</mat-label>
                <mat-select formControlName="Language" multiple>

                    <input type="text" placeholder="Pick the languages" aria-label="Number" matInput
                        [formControl]="myControl" [(ngModel)]="searchTextOne" autocomplete="off"
                        class="input-for-search-country">


                    <mat-option *ngFor='let language of languages | filter:searchTextOne' [value]="language.name">
                        {{language.name}}
                    </mat-option>
                </mat-select>

            </mat-form-field>

            <mat-form-field class="section-qustion-input" appearance="fill">
                <mat-label>Link of calendly:</mat-label>
                <input matInput #message maxlength="256" placeholder="Calendly link" formControlName="CalendlyLink">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Location</mat-label>
                <mat-select formControlName="Location" multiple>

                    <input type="text" placeholder="Pick the Location" aria-label="Number" matInput
                        [formControl]="myControl" [(ngModel)]="searchTextTwo" autocomplete="off"
                        class="input-for-search-country">


                    <mat-option *ngFor='let country of countries | filter:searchTextTwo' [value]="country.name">
                        {{country.name}}
                    </mat-option>
                </mat-select>

            </mat-form-field>
        </section>
        <p *ngIf="warnMessage" class="warn-message">*Please fill out the form </p>
        <section class="section-button">
            <button class="add-button" mat-raised-button color="primary">Add advisor</button>
        </section>

    </form>
</section>