import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddLangService {


  constructor(private db: AngularFirestore, ) { }





  addAlternativeLang(alternativeId: string, translateValue: any): Observable<void> {

    return from(this.db.doc(`alternatives/${alternativeId}`).set(translateValue, { merge: true }));
  }
  addInitativesLang(initativeId: string, translateValue: any): Observable<void> {

    return from(this.db.doc(`initativesAdmin/${initativeId}`).set(translateValue, { merge: true }));

  }
}
