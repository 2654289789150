

export function convertSnaps<T>(results: any) {
    return results.docs.map((snap: any) => {
        return {
            id: snap.id,
            ...(snap.data() as any),
        };
    }) as T[];

}
