import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-add-alternative',
  templateUrl: './user-add-alternative.component.html',
  styleUrls: ['./user-add-alternative.component.css']
})
export class UserAddAlternativeComponent implements OnInit {

  isUser = true;

  constructor() { }

  ngOnInit(): void {
  }

}
