<h2 mat-dialog-title>Edit Alternative</h2>

<mat-dialog-content [formGroup]="form">

    <mat-form-field appearance="fill">
        <mat-label>choose language to add</mat-label>
        <mat-select formControlName="dropListLang">
            <mat-option value="en">
                English
            </mat-option>
            <mat-option value="fr">
                French
            </mat-option>
            <mat-option value="ger">
                German
            </mat-option>
            <mat-option value="dut">
                Dutch
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>

        <textarea matInput placeholder="Description" formControlName="transDescription" class="description">

        </textarea>

    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

    <button mat-raised-button (click)="close()" class="close-form">
        Close
    </button>

    <button mat-raised-button color="primary" (click)="save()" class="save-form">
        Save
    </button>

</mat-dialog-actions>