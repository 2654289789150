import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { map, tap } from 'rxjs/operators';
import { AppUser } from '../../model/appuser';
import { Initiative } from '../../model/initiative';
import { InitiativeService } from '../../services/initiative.service';
import { UserService } from '../../services/user.service';
import { AddLanguageInitiativeComponent } from '../add-language-initiative/add-language-initiative.component';
import { EditInitiativeDialogComponent } from '../edit-initiative-dialog/edit-initiative-dialog.component';
@Component({
  selector: 'app-initiativs-card-list',
  templateUrl: './initiativs-card-list.component.html',
  styleUrls: ['./initiativs-card-list.component.css'],
})
export class InitiativsCardListComponent implements OnInit {
  @Input() initiatives!: Initiative[] | null;
  showMessage = false;
  appUser: AppUser;
  @Input() isuser = false;
  @Output() InitativeDeleted = new EventEmitter<Initiative>();
  @Output() InitativeApproved = new EventEmitter<Initiative>();
  @Output() initiativesEdited = new EventEmitter();

  constructor(
    private initiativeService: InitiativeService,
    private dialog: MatDialog,
    private afAuth: AngularFireAuth,
    public user: UserService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState
      .pipe(map((user) => (this.appUser = user)))
      .subscribe();
  }

  onCheckBox(initiative: any): void {
    this.initiativeService.AllInitativeAdmin().subscribe((res: Initiative[]) => {
      const isExist = res.find((ele) => ele.name === initiative.name);
      if (!!!isExist) {
        this.showMessage = false;
        this.initiativeService
          .createInitiativeAdmin(initiative, initiative.id)
          .subscribe((val: any) => {
            this.InitativeApproved.emit(initiative);
            this.onDeleteInitative(initiative);
          });
      } else {
        this.showMessage = true;
      }
    });
  }
  onDeleteInitative(initiative: any): void {
    if (!this.isuser) {
      this.initiativeService
        .deleteInitativeAdmin(initiative.id)
        .pipe(
          tap(() => {
            this.InitativeDeleted.emit(initiative);
          })
        )
        .subscribe();
    } else if (this.isuser) {
      this.initiativeService
        .deleteInitative(initiative.id)
        .pipe(
          tap(() => {
            this.InitativeDeleted.emit(initiative);
          })
        )
        .subscribe();
    }
  }
  onAddLanguage(initiative: any): void {
    const dialogConfigAddLangage = new MatDialogConfig();
    dialogConfigAddLangage.disableClose = true;
    dialogConfigAddLangage.autoFocus = true;
    dialogConfigAddLangage.minWidth = '400px';
    dialogConfigAddLangage.data = initiative;
    this.dialog
      .open(AddLanguageInitiativeComponent, dialogConfigAddLangage)
      .afterClosed()
      .subscribe();
  }
  editInitative(initiative: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '400px';
    dialogConfig.data = initiative;
    this.dialog
      .open(EditInitiativeDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((val) => {
        if (val) {
          this.initiativesEdited.emit();
        }
      });
  }

  onClickWbsite(initiative): void {
    const initiativeName = initiative.name.replace(/\s/g, '');

    const trackData = {
      userEmail: this.appUser?.email ?? 'unknown',
      userName: this.appUser?.displayName ?? 'unknown',
    };
    this.user
      .createUserTrack(
        trackData,
        this.appUser?.email,
        'initiative',
        initiativeName
      )
      .subscribe((val: any) => { });
  }
}
