export const AllQuestion =
    [
        {
            questionOne: 'Can we have your name please?', value: '1'
        },
        {
            questionTwo: 'Can we have your email address?', value: '2'
        },
        {
            questionThree: 'How old are you currently?', value: '3'
        },
        {
            questionFour: 'What is your current occupation? (one choice possible)', value: '4'
        },
        {
            questionFive: 'What’s your marital status?', value: '5'
        },
        {
            questionsix: 'In what language are you the most comfortable to talk about your finances? ', value: '6'
        },
        {
            questionSeven: 'What’s your monthly savings capacity?', value: '7'
        },
        {
            questionEight: 'How much money can you invest during the next 4 years?', value: '8'
        },
        {
            questionNine: 'Which format would you prefer for the meeting?', value: '9'
        }
        ,
        {
            questionTen: 'Which location would you like the meeting to be in?', value: '10'
        },
        {
            questionEleven: 'What do you want to talk about during the meeting?', value: '11'
        }
    ];
