import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alternative } from '../../model/alternative';
import { AlternativesService } from '../../services/alternatives.service';
@Component({
  selector: 'app-edit-alternative-dialog',
  templateUrl: './edit-alternative-dialog.component.html',
  styleUrls: ['./edit-alternative-dialog.component.css']
})
export class EditAlternativeDialogComponent implements OnInit {
  form: FormGroup;
  alternative: Alternative;

  constructor(private dialogRef: MatDialogRef<EditAlternativeDialogComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) alternative: Alternative,
              private alternativesService: AlternativesService,

  ) {
    this.alternative = alternative;
    this.form = this.fb.group({
      name: [alternative.name],
      description: [alternative.description],
      country: [alternative.country],
      areaOfOperation: [alternative.areaOfOperation],
      url: [alternative.urlForLogo],
      label: [alternative.label],
      amountToInvest: [alternative.amountToInvest],
      benefitsOfTheInvestment: [alternative.benefitsOfTheInvestment],
      Website: [alternative.Website],
      overallCategory: [alternative.overallCategory],
      fiscalAdvantage: [alternative.fiscalAdvantage],
      typeOfCustomer: [alternative.typeOfCustomer],

    });


  }

  ngOnInit(): void {

  }
  close(): void {
    this.dialogRef.close();

  }
  save(): void {
    const changes = this.form.value;
    this.alternativesService.updataAlternativesById(this.alternative.id, changes)
      .subscribe(() => {
        this.dialogRef.close(changes);
      });
  }

}
