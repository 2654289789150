import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlternativesComponent } from './pages/alternatives/alternatives.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { AlternativeDetilesPageComponent } from './pages/alternative-detiles-page/alternative-detiles-page.component';
import { AlternativeResolver } from './services/alternative.resolver';
import { LoginComponent } from './pages/login/login.component';
import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { UserSuggestionsComponent } from './pages/user-suggestions/user-suggestions.component';
import { AdminAddAlternativeComponent } from './pages/admin-add-alternative/admin-add-alternative.component';
import { UserAddAlternativeComponent } from './pages/user-add-alternative/user-add-alternative.component';
import { AlternativeUserResolver } from './services/alternativeUser.resolver';

import { QuizGitStartComponent } from './pages/quiz-git-start/quiz-git-start.component';
import { BanksAndSavingsRulesPageComponent } from './pages/banks-and-savings-rules-page/banks-and-savings-rules-page.component';
import { QuizMeetFinancialAdvisorComponent } from './pages/quiz-meet-financial-advisor/quiz-meet-financial-advisor.component';
import { ResultOfQuizMeetFinancialComponent } from './pages/result-of-quiz-meet-financial/result-of-quiz-meet-financial.component';
import { AddAdvisorComponent } from './pages/add-advisor/add-advisor.component';
import { AdvisorsResolver } from './services/advisor.resolver';
import { BlogEditorComponent } from './pages/blog-editor/blog-editor.component';
import { BlogCardComponent } from './pages/blog-card/blog-card.component';
import { BlogComponent } from './pages/blog/blog.component';
import { InitiativesComponent } from './pages/initiatives/initiatives.component';
import { AddInitiativeComponent } from './pages/add-initiative/add-initiative.component';
import { PensionFundPageComponent } from './pages/pension-fund-page/pension-fund-page.component';
import { InvestmentsPageComponent } from './pages/investments-page/investments-page.component';
import { ImpactYourMoneyComponent } from './pages/impact-your-money/impact-your-money.component';
import { LastStepComponent } from './pages/last-step/last-step.component';
import { AlternativeInvestmentsComponent } from './pages/alternative-investments/alternative-investments.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { MeasureComponent } from './pages/measure/measure.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const adminOnly = () => hasCustomClaim('admin');
const redirectToHomePage = !redirectUnauthorizedToLogin;
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  { path: 'home', component: HomePageComponent },

  {
    path: 'alternatives',
    component: AlternativesComponent,
  },
  {
    path: 'alternatives/:catgory',
    component: AlternativesComponent
  },

  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: adminOnly,
    },
  },
  {
    path: 'admin-add-alternative',
    component: AdminAddAlternativeComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: adminOnly,
    },
  },
  {
    path: 'user-add-alternative',
    component: UserAddAlternativeComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToHome
    }
  },
  { path: 'user-suggestions', component: UserSuggestionsComponent },

  {
    path: 'alternative/:alternativeName',
    component: AlternativeDetilesPageComponent,

    resolve: {
      alternative: AlternativeResolver,
    },

    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'user-alternative/:alternativeName',
    component: AlternativeDetilesPageComponent,

    resolve: {
      alternative: AlternativeUserResolver,
    },
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'quiz-git-start',
    component: QuizGitStartComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'quiz-meet-financial-advisor',
    component: QuizMeetFinancialAdvisorComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'banks-and-savings-rules',
    component: BanksAndSavingsRulesPageComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    }
  },
  {
    path: 'pension-fund',
    component: PensionFundPageComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    }
  }, {
    path: 'investments',
    component: InvestmentsPageComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    }
  },
  {
    path: 'alternative-investments',
    component: AlternativeInvestmentsComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    }
  },
  {
    path: 'impact-your-money',
    component: ImpactYourMoneyComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    }
  },
  {
    path: 'last-step',
    component: LastStepComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    }
  },
  {
    path: 'meet-financial-advisor/:Language/:Location',
    component: ResultOfQuizMeetFinancialComponent,
    resolve: {
      advaisor: AdvisorsResolver,
    },
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'add-advisor',
    component: AddAdvisorComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: adminOnly,
    },
  },
  {
    path: 'add-post',
    component: BlogEditorComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: adminOnly,
    },
  },
  {
    path: 'editpost/:id',
    component: BlogEditorComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: adminOnly,
    }
  },
  { path: 'read', component: BlogCardComponent },
  { path: 'blog/:id/:slug', component: BlogComponent },

  {
    path: 'initiatives',
    component: InitiativesComponent
  },
  {
    path: 'add-initiatives',
    component: AddInitiativeComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    }
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'measure',
    component: MeasureComponent
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    }
  },
  {
    path: '**',
    redirectTo: '/home'
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
