<section class="section-one">
    <mat-card *ngFor="let initiative of initiatives" class="initiative-card mat-elevation-z10">

        <mat-card-header>

            <mat-card-title>{{initiative.name}}</mat-card-title>

        </mat-card-header>

        <mat-card-content>
            <p>description:{{initiative.description}}</p>
            <p>{{initiative.country}}</p>
            <div class="section-button">
                <a [href]="initiative.link" target="_blank">
                    <button mat-raised-button color="primary" (click)="onClickWbsite(initiative)"  class="button-website"> website</button>
                </a>
            </div>
        </mat-card-content>

        <mat-card-actions class="initiative-actions">

            <div class="delete-approve-buttons">
                <ng-container *ngIf="(user.roles$ | async) as roles">
                    <ng-container *ngIf="roles.admin">
                        <button *ngIf="!isuser" mat-mini-fab class="edit-Initative" (click)="editInitative(initiative)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-mini-fab *ngIf="isuser" class="checkbox-button" (click)="onCheckBox(initiative)">
                            <mat-icon>checkbox</mat-icon>
                        </button>
                        <button mat-mini-fab color="warn" (click)="onDeleteInitative(initiative)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button *ngIf="!isuser" mat-mini-fab class="add-language-icon"
                            (click)="onAddLanguage(initiative)">
                            <mat-icon>language</mat-icon>
                        </button>
                    </ng-container>
                </ng-container>
            </div>
        </mat-card-actions>
        <h1 class="error-message" *ngIf="showMessage">*Title is already used</h1>

    </mat-card>
</section>