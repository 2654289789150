import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { from, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class StoreAnsweQuizsService {

  constructor(private db: AngularFirestore) { }

  storeQuiz(emailAndQuiz: any): Observable<any> {
    const quiz = { ...emailAndQuiz };
    let save$: Observable<any>;
    save$ = from(this.db.collection('quizHowToStrat').add(quiz));
    return save$;
  }
  storeQuizMeetFinancialAdvisor(allQuestionsAndAnswers: any): Observable<any> {
    const quiz = { ...allQuestionsAndAnswers };
    let save$: Observable<any>;
    save$ = from(this.db.collection('quizMeetFinancialAdvisor').add(quiz));
    return save$;
  }

}
