import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { Alternative } from '../model/alternative';
import { convertSnaps } from './db-utils';
import { concatMap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserAlternativesService {

  constructor(private db: AngularFirestore) { }
  AllUserAlternatives(): Observable<Alternative[]> {
    return this.db.collection('userAlternatives').get().pipe(map((result) => convertSnaps<Alternative>(result)));
  }

  deleteUserAlternative(alternativeId: string): Observable<void> {
    return from(this.db.doc(`userAlternatives/${alternativeId}`).delete());
  }

  updataUserAlternativesById(alternativeId: string, changes: Partial<Alternative>): Observable<void> {
    return from(this.db.doc(`userAlternatives/${alternativeId}`).update(changes));

  }

  createUserAlternative(newAltrnative: Partial<Alternative>, alternativeId?: string): Observable<any> {
    return this.db.collection('userAlternatives',
      ref => ref.orderBy('seqNo', 'desc').limit(1))
      .get()
      .pipe(concatMap(result => {
        const alternatives = convertSnaps<Alternative>(result);
        const lastAlternativSeqNo = alternatives[0]?.seqNo ?? 0;
        const alternative = {
          ...newAltrnative,
          seqNo: lastAlternativSeqNo + 1
        };
        let save$: Observable<any>;
        // tslint:disable-next-line:prefer-conditional-expression
        if (alternativeId) {
          save$ = from(this.db.doc(`userAlternatives/${alternativeId}`).set(alternative));
        }
        else {
          save$ = from(this.db.collection('userAlternatives').add(alternative));

        }

        return save$
          .pipe(map(res => {
            return {
              id: alternativeId ?? res.id,
              ...alternative
            };
          }));
      })
      );

  }


  findAlternativeUserByUrl(alternativeName: any): any {
    return this.db.collection('userAlternatives',
      ref => ref.where('name', '==', alternativeName))
      .get().pipe(map(result => {
        const alternatievs = convertSnaps<Alternative>(result);
        return alternatievs.length === 1 ? alternatievs[0] : null;

      }
      ));
  }

}
