<section class="section-one">
    <mat-tab-group animationDuration="0ms" mat-align-tabs="center" (selectedTabChange)="tabClick($event)">
        <mat-tab [label]='"ADMIN.ADMIN_INFORMATION_TAB" | translate' class="tabs1">

        </mat-tab>
        <mat-tab [label]='"ADMIN.ALTERNATIVES_TAB" | translate' class="second-tab">
            <section class="section-alternatives-by-admin">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label> {{ "ADMIN.SEARCH_BUTTON" | translate }}</mat-label>
                    <input [(ngModel)]="searchText" matInput [placeholder]='"ADMIN.SEARCH_BUTTON" | translate' />
                </mat-form-field>
                <ng-container *ngIf="user.roles$ | async as roles">
                    <ng-container *ngIf="roles.admin">
                        <button mat-raised-button color="primary" class="Add-alternative"
                            routerLink="/admin-add-alternative">
                            {{ "ADMIN.ADD_ALTERNATIVE_BUTTON" | translate }}

                            <mat-icon class="add-course-btn">add_circle</mat-icon>
                        </button>
                    </ng-container>
                </ng-container>
            </section>

            <div class="alternative-panel">
                <app-alternatives-card-list [alternatives]="alternativesList$ | async | filter: searchText"
                    (alternativeEdited)="reloadlAternatives()" (alternativeDeleted)="reloadlAternatives()">
                </app-alternatives-card-list>
            </div>
            <div class="loading-bar">
                <mat-spinner *ngIf="loadingShowOne"></mat-spinner>
            </div>
            <h1 *ngIf="warnMessageOne&&!loadingShowOne" class="warnMessage">
                * {{ "ADMIN.NO-ALTERNATIVES_MESSAGE" | translate }}
            </h1>
        </mat-tab>

        <mat-tab [label]='"ADMIN.ALTERNATIVES-BY-USER_TAB" | translate'>
            <div class="alternative-panel">
                <app-alternatives-card-list [alternatives]="userAlternativsList$ | async"
                    (alternativeEdited)="reloadUserAlternatives()" (alternativeDeleted)="reloadUserAlternatives()"
                    (alternativeApproved)="reloadUserAlternatives()" [isuser]="true">
                </app-alternatives-card-list>
            </div>
            <div class="loading-bar">
                <mat-spinner *ngIf="loadingShowTwo"></mat-spinner>
            </div>

            <h1 *ngIf="warnMessageTwo&&!loadingShowTwo" class="warnMessage">
                *{{ "ADMIN.NO-ALTERNATIVES_MESSAGE" | translate }}
            </h1>
        </mat-tab>
        <mat-tab [label]='"ADMIN.INITIATIVE_TAB" | translate'>
            <div class="initiative-panel">

                <app-initiativs-card-list [initiatives]="initiativeListAdmin$ | async"
                    (initiativesEdited)="reloadlInitiativeAdmin()" (InitativeDeleted)="reloadlInitiativeAdmin()">

                </app-initiativs-card-list>
                <div class="loading-bar">
                    <mat-spinner *ngIf="loadingShowFour"></mat-spinner>
                </div>
                <h1 *ngIf="warnMessageFour&&!loadingShowFour" class="warnMessage">
                    *{{ "ADMIN.NO-INITIATIVE_MESSAGE" | translate }}
                </h1>

            </div>
        </mat-tab>
        <mat-tab [label]='"ADMIN.INITIATIVE-BY-USER_TAB" | translate'>
            <div class="initiative-panel">
                <app-initiativs-card-list [initiatives]="initiativeList$ | async" [isuser]="true"
                    (InitativeApproved)="reloadlInitiative()" (InitativeDeleted)="reloadlInitiative()">

                </app-initiativs-card-list>
                <div class="loading-bar">
                    <mat-spinner *ngIf="loadingShowThree"></mat-spinner>
                </div>
                <h1 *ngIf="warnMessageThree&&!loadingShowThree" class="warnMessage">
                    *{{ "ADMIN.NO-INITIATIVE_MESSAGE" | translate }}
                </h1>
            </div>
        </mat-tab>
        <mat-tab [label]='"ADMIN.ADD-ADVISORS_TAB" | translate'>
            <div class="advisor-panel">
                <button mat-raised-button color="primary" class="add-advisor-button" routerLink="/add-advisor">
                    {{ "ADMIN.ADD-ADVISORS_TAB-BUTTON" | translate }}</button>
                <app-advisors-card-list [advisors]="advisorsList$ | async" (advisorsEdited)="reloadlAdvisors()"
                    (advisorsDeleted)="reloadlAdvisors()">

                </app-advisors-card-list>
                <div class="loading-bar">
                    <mat-spinner *ngIf="loadingShowFive"></mat-spinner>
                </div>
                <h1 *ngIf="warnMessageFive&&!loadingShowFive" class="warnMessage">
                    *{{ "ADMIN.NO-ADVISORS_MESSAGE" | translate }}
                </h1>


            </div>
        </mat-tab>

    </mat-tab-group>
</section>