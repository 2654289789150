import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransAlternative } from '../../model/transAlternative';
import { AddLangService } from '../../services/add-lang.service';
import { Initiative } from '../../model/initiative';

@Component({
  selector: 'app-add-language-initiative',
  templateUrl: './add-language-initiative.component.html',
  styleUrls: ['./add-language-initiative.component.css']
})
export class AddLanguageInitiativeComponent implements OnInit {
  form: FormGroup;
  initiative: Initiative;
  translateValue: any;

  constructor(private dialogRef: MatDialogRef<AddLanguageInitiativeComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) initiative: Initiative, private addLangService: AddLangService) {
    this.initiative = initiative;
    this.form = this.fb.group({
      dropListLang: [''],
      transDescription: [initiative.description],
    });
  }

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close();
  }
  save(): void {

    const dropListLang = this.form.value.dropListLang;
    const formValues = this.form.value;

    this.translateValue = {
      transDescription: {
        [dropListLang]: formValues.transDescription
      },

    };

    this.addLangService.addInitativesLang(this.initiative.id, this.translateValue)
      .subscribe(() => {
        this.dialogRef.close(this.translateValue);
      });
  }


}
