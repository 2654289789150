<section class="section-one">
  <h1>{{ "GET INVOLVED.SEARCH_INITIATIVE" | translate }}</h1>
  <section class="container-section-one">
    <div class="div-container-contain-select-country">
      <h4 class="your-country">{{ "ACT.SELECT-COUNTRY" | translate }}</h4>
      <mat-form-field appearance="fill">
        <mat-label>{{
          "ACT.SELECT-COUNTRY_PLACEHOLDER_LABEL" | translate
          }}</mat-label>
        <mat-select [formControl]="countryControl" required>
          <mat-option *ngFor="let country of allCountry" (click)="changeNameCountry($event)" [value]="country">
            {{ country }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="countryControl.hasError('required')">{{ "ACT.SELECT-COUNTRY_MAT-ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" class="button-for-search" (click)="search()">
      {{ "ACT.SEARCH_BUTTON" | translate }}
    </button>

    <h1 class="choose-message" *ngIf="warnMesaage">
      *{{ "GET INVOLVED.CHOOSE_MESSAGE" | translate }}
    </h1>
  </section>
</section>
<div>
  <app-initiativs-card-list [initiatives]="initiativeList$ | async" (initiativesEdited)="reloadlInitiative()"
    (InitativeDeleted)="reloadlInitiative()">
  </app-initiativs-card-list>
  <div class="loading-bar">
    <mat-spinner *ngIf="showLoading"></mat-spinner>
  </div>
  <div *ngIf="showAddSuggestions" class="class-button-for-add-more-suggestions">
    <button mat-raised-button color="primary" class="button-for-add-more-suggestions" routerLink="/user-suggestions">
      {{ "ACT.ADD_SUGGESTIONS_BUTTON" | translate }}
    </button>
  </div>
</div>