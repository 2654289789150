import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl
} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { Alternative } from '../../model/alternative';
import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';

import { AlternativesService } from '../../services/alternatives.service';
import { Countries } from './countries';
import { UserAlternativesService } from '../../services/user-alternatives.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SuccessAddDialogComponent } from '../success-add-dialog/success-add-dialog.component';

@Component({
  selector: 'app-add-alternative',
  templateUrl: './add-alternative.component.html',
  styleUrls: ['./add-alternative.component.css']
})
export class AddAlternativeComponent implements OnInit {
  isLinear = false;
  alternativeId = '';
  alternativeform!: FormGroup;
  errorMessage = '';
  searchText = '';
  myControl = new FormControl();
  countries = Countries;
  warnMessage = false;

  @Input() isAdmin = false;
  @Input() isUser = false;


  constructor(private formBuilder: FormBuilder,
              private alternativeService: AlternativesService,
              private userAlternativesService: UserAlternativesService,
              private afs: AngularFirestore,
              private router: Router,
              public translate: TranslateService,
              private location: Location,
              private dialog: MatDialog
  ) { }


  ngOnInit(): void {
    this.alternativeId = this.afs.createId();
    this.alternativeform = this.formBuilder.group({
      title: ['', { validators: [Validators.required] }],
      country: ['', { validators: [Validators.required] }],
      areaOfOperation: ['', { validators: [Validators.required] }],
      urlForLogo: [''],
      label: [''],
      minimalAmount: [''],
      benefitsOfTheInvestment: [''],
      longDescription: [''],
      category: ['', { validators: [Validators.required] }],
      fiscalAdvantage: [''],
      typeOfCustomer: [''],
      linkWebsite: ['', { validators: [Validators.required] }],

    });
  }
  onAddAlternative(): void {
    const title = this.alternativeform.value.title;
    const country = this.alternativeform.value.country;
    const areaOfOperation = this.alternativeform.value.areaOfOperation;
    const category = this.alternativeform.value.category;
    const link = this.alternativeform.value.linkWebsite;
    if (title && country && areaOfOperation && category && link) {
      this.warnMessage = false;
      const val = this.alternativeform.value;

      const newAlternative: Partial<Alternative> = {
        name: val.title,
        country: val.country,
        areaOfOperation: val.areaOfOperation,
        urlForLogo: val.urlForLogo,
        label: val.label,
        fiscalAdvantage: val.fiscalAdvantage,
        amountToInvest: val.minimalAmount,
        description: val.longDescription,
        benefitsOfTheInvestment: val.benefitsOfTheInvestment,
        overallCategory: val.category,
        typeOfCustomer: val.typeOfCustomer,
        Website: val.linkWebsite
      };

      if (this.isAdmin) {

        this.alternativeService.AllAlternatives().subscribe((res: Alternative[]) => {

          const isExist = res.find(ele => ele.name === newAlternative.name);

          if (!!!isExist) {
            this.alternativeService.createAlternative(newAlternative, this.alternativeId)
              .pipe(tap(alternative => {
                this.router.navigateByUrl('/admin');
              }),
                catchError(err => {
                  return throwError(err);
                })
              ).subscribe();

          } else {
            this.errorMessage = 'Title is already used';

          }

        });
      } else if (this.isUser) {

        this.userAlternativesService.AllUserAlternatives().subscribe((res: Alternative[]) => {

          const isExist = res.find(ele => ele.name === newAlternative.name);

          if (!!!isExist) {
            this.userAlternativesService.createUserAlternative(newAlternative, this.alternativeId)
              .pipe(tap(alternative => {
                const dialogConfigSuccessAdd = new MatDialogConfig();
                dialogConfigSuccessAdd.disableClose = true;
                dialogConfigSuccessAdd.autoFocus = true;
                dialogConfigSuccessAdd.minWidth = '400px';
                this.dialog.open(SuccessAddDialogComponent, dialogConfigSuccessAdd)
                  .afterClosed()
                  .subscribe();
              }),
                catchError(err => {
                  return throwError(err);
                })
              ).subscribe();

          } else {
            this.errorMessage = 'Title is already used';

          }

        });

      }
    } else {

      this.warnMessage = true;
    }

  }
  goBack(): void {
    this.location.back();


  }
}

