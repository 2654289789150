import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Post } from '../../model/post';
import { AppUser } from '../../model/appuser';
import { BlogService } from '../../services/blog.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-blog-editor',
  templateUrl: './blog-editor.component.html',
  styleUrls: ['./blog-editor.component.css'],
  providers: [DatePipe],
})
export class BlogEditorComponent implements OnInit, OnDestroy {
  public Editor = ClassicEditor;
  ckeConfig: any;
  public postData = new Post();
  appUser: AppUser;
  formTitle = 'Add';
  postId;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    private blogService: BlogService,
    private _router: Router,
    private afAuth: AngularFireAuth
  ) {
    if (this._route.snapshot.params.id) {
      this.postId = this._route.snapshot.paramMap.get('id');
    }
    this.afAuth.authState
      .pipe(map((user) => (this.appUser = user)))
      .subscribe();
  }

  ngOnInit(): void {
    this.setEditorConfig();
    if (this.postId) {
      this.formTitle = 'Edit';
      this.blogService
        .getPostbyId(this.postId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => this.setPostFormData(result));
    }
  }
  setPostFormData(postFormData: Post): void {
    this.postData.title = postFormData.title;
    this.postData.content = postFormData.content;
    this.postData.imageURL = postFormData.imageURL;
  }

  saveBlogPost(): void {
    if (this.postId) {
      this.blogService.updatePost(this.postId, this.postData).then(() => {
        this._router.navigate(['/read']);
      });
    } else {
      this.postData.createdDate = this.datePipe.transform(
        Date.now(),
        'MM-dd-yyyy HH:mm'
      );
      this.postData.author = this.appUser.displayName;
      this.postData.authorEmail = this.appUser.email;

      this.blogService.createPost(this.postData).then(() => {
        this._router.navigate(['/read']);
      });
    }
  }

  setEditorConfig(): void {
    this.ckeConfig = {
      removePlugins: ['ImageUpload', 'MediaEmbed'],
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4',
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5',
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6',
          },
          { model: 'Formatted', view: 'pre', title: 'Formatted' },
        ],
      },
    };
  }

  cancel(): void {
    this._router.navigate(['/read']);
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
