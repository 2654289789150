
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Advisors } from '../model/advisors';
import { AddAdvisorsService } from './add-advisors.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdvisorsResolver implements Resolve<Advisors>{

    constructor(private addAdvisorsService: AddAdvisorsService) { }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<Advisors> {

        const Language = route.paramMap.get('Language');
        const Location = route.paramMap.get('Location');
        return this.addAdvisorsService.findAdvisorByUrl(Language, Location);
    }


}
