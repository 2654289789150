<section class="container-section-one">
  <p>{{ "ACT.SECTION_ONE_TEXT" | translate }}</p>
  <p class="paragraph-one">
    {{ "ACT.SECTION_ONE_PARAGRAPH_ONE" | translate }}
    <a routerLink="/login">
      {{ "ACT.SECTION_ONE_PARAGRAPH_ONE-LOGIN" | translate }}</a>
    {{ "ACT.SECTION_ONE_PARAGRAPH_TWO" | translate }}
  </p>

  <div class="div-container-contain-select-country">
    <h4 class="your-country">{{ "ACT.SELECT-COUNTRY" | translate }}</h4>
    <mat-form-field appearance="fill">
      <mat-label>{{
        "ACT.SELECT-COUNTRY_PLACEHOLDER_LABEL" | translate
        }}</mat-label>
      <mat-select [formControl]="countryControl" required>
        <mat-option *ngFor="let country of allCountry" (click)="changeNameCountry($event)" [value]="country">
          {{ country }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="countryControl.hasError('required')">{{ "ACT.SELECT-COUNTRY_MAT-ERROR" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <section class="container-section-three">
    <mat-radio-button class="radio-button-for-category" [checked]="checkedOne" value="Bank"
      (change)="changeNameCategory($event)">Bank</mat-radio-button>
    <mat-radio-button class="radio-button-for-category" [checked]="checkedTwo" value="Investment"
      (change)="changeNameCategory($event)">Investment
    </mat-radio-button>
    <mat-radio-button class="radio-button-for-category" [checked]="checkedThree" value="Insurance"
      (change)="changeNameCategory($event)">Insurance
    </mat-radio-button>
    <mat-radio-button class="radio-button-for-category" [checked]="checkedFour" value="Pension Fund"
      (change)="changeNameCategory($event)">Pension Fund</mat-radio-button>
    <p *ngIf="showMessage && !emptyValue" class="show-message">
      *{{ "ACT.NO-RESULT_MESSAGE" | translate }}
    </p>
  </section>
  <button mat-raised-button color="primary" class="button-for-get-an-external-opinion-and-search" (click)="search()">
    {{ "ACT.SEARCH_BUTTON" | translate }}
  </button>
  <h1 class="choose-message" *ngIf="emptyValue">
    *{{ "ACT.CHOOSE_MESSAGE" | translate }}
  </h1>
</section>
<div>
  <app-alternatives-card-list [alternatives]="alternativesList$ | async" (alternativeEdited)="reloadlAlternatives()"
    (alternativeDeleted)="reloadlAlternatives()">
  </app-alternatives-card-list>
  <div class="loading-bar">
    <mat-spinner *ngIf="showLoading"></mat-spinner>
  </div>
</div>
<div *ngIf="showAddSuggestions" class="class-button-for-add-more-suggestions">
  <button mat-raised-button color="primary" class="button-for-add-more-suggestions" routerLink="/user-suggestions">
    {{ "ACT.ADD_SUGGESTIONS_BUTTON" | translate }}
  </button>
</div>
<section class="container-section-two">
  <p class="paragraph-two">
    {{ "ACT.SECTION_TWO_PARAGRAPH_ONE" | translate }}
  </p>
  <button mat-raised-button color="primary" class="button-for-get-an-external-opinion-and-search"
    routerLink="/quiz-git-start">
    {{ "ACT.EXTERNAL-OPINIOON_BUTTON" | translate }}
  </button>
</section>