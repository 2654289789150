import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-impact-your-money',
  templateUrl: './impact-your-money.component.html',
  styleUrls: ['./impact-your-money.component.css']
})
export class ImpactYourMoneyComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
