export const Countries =
    [
        {
            name: 'Andorra', value: '1'
        },
        {
            name: 'United Arab Emirates', value: '2'
        },
        {
            name: 'Afghanistan', value: '3'
        },
        {
            name: 'Antigua and Barbuda', value: '4'
        },
        {
            name: 'Anguilla', value: '5'
        },
        {
            name: 'Albania', value: '6'
        },
        {
            name: 'Armenia', value: '7'
        },
        {
            name: 'Netherlands Antilles', value: '8'
        },
        {
            name: 'Angola', value: '9'
        },
        {
            name: 'Antarctica', value: '10'
        },
        {
            name: 'Argentina', value: '11'
        },
        {
            name: 'American Samoa', value: '12'
        },
        {
            name: 'Austria', value: '13'
        },
        {
            name: 'Australia', value: '14'
        },
        {
            name: 'Aruba', value: '15'
        },
        {
            name: 'Aland Islands', value: '16'
        },
        {
            name: 'Azerbaijan', value: '17'
        },
        {
            name: 'Bosnia and Herzegovina', value: '18'
        },
        {
            name: 'Barbados', value: '19'
        },
        {
            name: 'Bangladesh', value: '20'
        },
        {
            name: 'Belgium', value: '21'
        },
        {
            name: 'Burkina Faso', value: '22'
        },
        {
            name: 'Bulgaria', value: '23'
        },
        {
            name: 'Bahrain', value: '24'
        },
        {
            name: 'Burundi', value: '25'
        },
        {
            name: 'Benin', value: '26'
        },
        {
            name: 'Saint Barthélemy', value: '27'
        },
        {
            name: 'Bermuda', value: '28'
        },
        {
            name: 'Brunei', value: '29'
        },
        {
            name: 'Bolivia', value: '30'
        },
        {
            name: 'Bonaire, Saint Eustatius and Saba ', value: '31'
        },
        {
            name: 'Brazil', value: '32'
        },
        {
            name: 'Bahamas', value: '33'
        },
        {
            name: 'Bhutan', value: '34'
        },
        {
            name: 'Bouvet Island', value: '35'
        },
        {
            name: 'Botswana', value: '36'
        },
        {
            name: 'Belarus', value: '37'
        },
        {
            name: 'Belize', value: '38'
        },
        {
            name: 'Canada', value: '39'
        },
        {
            name: 'Cocos Islands', value: '40'
        },
        {
            name: 'Democratic Republic of the Congo', value: '41'
        },
        {
            name: 'Central African Republic', value: '42'
        },
        {
            name: 'Republic of the Congo', value: '43'
        },
        {
            name: 'Switzerland', value: '44'
        },
        {
            name: 'Ivory Coast', value: '45'
        },
        {
            name: 'Cook Islands', value: '46'
        },
        {
            name: 'Chile', value: '47'
        },
        {
            name: 'Cameroon', value: '48'
        },
        {
            name: 'China', value: '49'
        },
        {
            name: 'Colombia', value: '50'
        },
        {
            name: 'Costa Rica', value: '51'
        },
        {
            name: 'Serbia and Montenegro', value: '52'
        },
        {
            name: 'Cuba', value: '53'
        },
        {
            name: 'Cape Verde', value: '54'
        },
        {
            name: 'Curaçao', value: '55'
        },
        {
            name: 'Christmas Island', value: '56'
        },
        {
            name: 'Cyprus', value: '57'
        },
        {
            name: 'Czech Republic', value: '58'
        },
        {
            name: 'Germany', value: '59'
        },
        {
            name: 'Djibouti', value: '60'
        },
        {
            name: 'Denmark', value: '61'
        },
        {
            name: 'Dominica', value: '62'
        },
        {
            name: 'Dominican Republic', value: '63'
        },
        {
            name: 'Algeria', value: '64'
        },
        {
            name: 'Ecuador', value: '65'
        },
        {
            name: 'Estonia', value: '66'
        },
        {
            name: 'Egypt', value: '67'
        },
        {
            name: 'Western Sahara', value: '68'
        },
        {
            name: 'Eritrea', value: '69'
        },
        {
            name: 'Spain', value: '70'
        },
        {
            name: 'Ethiopia', value: '71'
        },
        {
            name: 'Finland', value: '72'
        },
        {
            name: 'Fiji', value: '73'
        },
        {
            name: 'Falkland Islands', value: '74'
        },
        {
            name: 'Micronesia', value: '75'
        },
        {
            name: 'Faroe Islands', value: '76'
        },
        {
            name: 'France', value: '77'
        },
        {
            name: 'Gabon', value: '78'
        },
        {
            name: 'United Kingdom', value: '79'
        },
        {
            name: 'Grenada', value: '80'
        },
        {
            name: 'Georgia', value: '81'
        },
        {
            name: 'French Guiana', value: '82'
        },
        {
            name: 'Guernsey', value: '83'
        },
        {
            name: 'Ghana', value: '84'
        },
        {
            name: 'Gibraltar', value: '85'
        },
        {
            name: 'Greenland', value: '86'
        },
        {
            name: 'Gambia', value: '87'
        },
        {
            name: 'Guinea', value: '88'
        },
        {
            name: 'Guadeloupe', value: '89'
        },
        {
            name: 'Equatorial Guinea', value: '90'
        },
        {
            name: 'Greece', value: '91'
        },
        {
            name: 'South Georgia and the South Sandwich Islands', value: '92'
        },
        {
            name: 'Guatemala', value: '93'
        },
        {
            name: 'Guam', value: '94'
        },
        {
            name: 'Guinea-Bissau', value: '95'
        },
        {
            name: 'Guyana', value: '96'
        },
        {
            name: 'Hong Kong', value: '97'
        },
        {
            name: 'Heard Island and McDonald Islands', value: '98'
        },
        {
            name: 'Honduras', value: '99'
        },
        {
            name: 'Croatia', value: '100'
        },
        {
            name: 'Haiti', value: '101'
        },
        {
            name: 'Hungary', value: '102'
        },
        {
            name: 'Indonesia', value: '103'
        },
        {
            name: 'Ireland', value: '104'
        },
        {
            name: 'Israel', value: '105'
        },
        {
            name: 'Isle of Man', value: '106'
        },
        {
            name: 'India', value: '107'
        },
        {
            name: 'British Indian Ocean Territory', value: '108'
        },
        {
            name: 'Iraq', value: '109'
        },
        {
            name: 'Iran', value: '110'
        },
        {
            name: 'Iceland', value: '111'
        },
        {
            name: 'Italy', value: '112'
        },
        {
            name: 'Jersey', value: '113'
        },
        {
            name: 'Jamaica', value: '114'
        },
        {
            name: 'Jordan', value: '115'
        },
        {
            name: 'Japan', value: '116'
        },
        {
            name: 'Kenya', value: '117'
        },
        {
            name: 'Kyrgyzstan', value: '118'
        },
        {
            name: 'Cambodia', value: '119'
        },
        {
            name: 'Kiribati', value: '120'
        },
        {
            name: 'Comoros', value: '121'
        },
        {
            name: 'Saint Kitts and Nevis', value: '122'
        },
        {
            name: 'North Korea', value: '123'
        },
        {
            name: 'South Korea', value: '124'
        },
        {
            name: 'Kuwait', value: '125'
        },
        {
            name: 'Cayman Islands', value: '126'
        },
        {
            name: 'Kazakhstan', value: '127'
        },
        {
            name: 'Laos', value: '128'
        },
        {
            name: 'Lebanon', value: '129'
        },
        {
            name: 'Saint Lucia', value: '130'
        },
        {
            name: 'Liechtenstein', value: '131'
        },
        {
            name: 'Sri Lanka', value: '132'
        },
        {
            name: 'Liberia', value: '133'
        },
        {
            name: 'Lesotho', value: '134'
        },
        {
            name: 'Lithuania', value: '135'
        },
        {
            name: 'Luxembourg', value: '136'
        },
        {
            name: 'Latvia', value: '137'
        },
        {
            name: 'Libya', value: '138'
        },
        {
            name: 'Morocco', value: '139'
        },
        {
            name: 'Monaco', value: '140'
        },
        {
            name: 'Moldova', value: '141'
        },
        {
            name: 'Montenegro', value: '142'
        },
        {
            name: 'Saint Martin', value: '143'
        },
        {
            name: 'Madagascar', value: '144'
        },
        {
            name: 'Marshall Islands', value: '145'
        },
        {
            name: 'Macedonia', value: '146'
        },
        {
            name: 'Mali', value: '147'
        },
        {
            name: 'Myanmar', value: '148'
        },
        {
            name: 'Mongolia', value: '149'
        },
        {
            name: 'Macao', value: '150'
        },
        {
            name: 'Northern Mariana Islands', value: '151'
        },
        {
            name: 'Martinique', value: '152'
        },
        {
            name: 'Mauritania', value: '153'
        },
        {
            name: 'Montserrat', value: '154'
        },
        {
            name: 'Malta', value: '155'
        },
        {
            name: 'Mauritius', value: '156'
        },
        {
            name: 'Maldives', value: '157'
        },
        {
            name: 'Malawi', value: '158'
        },
        {
            name: 'Mexico', value: '159'
        },
        {
            name: 'Malaysia', value: '160'
        },
        {
            name: 'Mozambique', value: '161'
        },
        {
            name: 'Namibia', value: '162'
        },
        {
            name: 'New Caledonia', value: '163'
        },
        {
            name: 'Niger', value: '164'
        },
        {
            name: 'Norfolk Island', value: '165'
        },
        {
            name: 'Nigeria', value: '166'
        },
        {
            name: 'Nicaragua', value: '167'
        },
        {
            name: 'Netherlands', value: '168'
        },
        {
            name: 'Norway', value: '169'
        },
        {
            name: 'Nepal', value: '170'
        },
        {
            name: 'Nauru', value: '171'
        },
        {
            name: 'Niue', value: '172'
        },
        {
            name: 'New Zealand', value: '173'
        },
        {
            name: 'Oman', value: '174'
        },
        {
            name: 'Panama', value: '175'
        },
        {
            name: 'Peru', value: '176'
        },
        {
            name: 'French Polynesia', value: '177'
        },
        {
            name: 'Papua New Guinea', value: '178'
        },
        {
            name: 'Philippines', value: '179'
        },
        {
            name: 'Pakistan', value: '180'
        },
        {
            name: 'Poland', value: '181'
        },
        {
            name: 'Saint Pierre and Miquelon', value: '182'
        },
        {
            name: 'Pitcairn', value: '183'
        },
        {
            name: 'Puerto Rico', value: '184'
        },
        {
            name: 'Palestinian Territory', value: '185'
        },
        {
            name: 'Portugal', value: '186'
        },
        {
            name: 'Palau', value: '187'
        },
        {
            name: 'Paraguay', value: '188'
        },
        {
            name: 'Qatar', value: '189'
        },
        {
            name: 'Reunion', value: '190'
        },
        {
            name: 'Romania', value: '191'
        },
        {
            name: 'Serbia', value: '192'
        },
        {
            name: 'Russia', value: '193'
        },
        {
            name: 'Rwanda', value: '194'
        },
        {
            name: 'Saudi Arabia', value: '195'
        },
        {
            name: 'Solomon Islands', value: '196'
        },
        {
            name: 'Seychelles', value: '197'
        },
        {
            name: 'Sudan', value: '198'
        },
        {
            name: 'Sweden', value: '199'
        },
        {
            name: 'Singapore', value: '200'
        },
        {
            name: 'Saint Helena', value: '201'
        },
        {
            name: 'Slovenia', value: '202'
        },
        {
            name: 'Svalbard and Jan Mayen', value: '203'
        },
        {
            name: 'Slovakia', value: '204'
        },
        {
            name: 'Sierra Leone', value: '205'
        },
        {
            name: 'San Marino', value: '206'
        },
        {
            name: 'Senegal', value: '207'
        },
        {
            name: 'Somalia', value: '208'
        },
        {
            name: 'Suriname', value: '209'
        },
        {
            name: 'South Sudan', value: '210'
        },
        {
            name: 'Sao Tome and Principe', value: '211'
        },
        {
            name: 'El Salvador', value: '212'
        },
        {
            name: 'Sint Maarten', value: '213'
        },
        {
            name: 'Syria', value: '214'
        },
        {
            name: 'Swaziland', value: '215'
        },
        {
            name: 'Turks and Caicos Islands', value: '216'
        },
        {
            name: 'Chad', value: '217'
        },
        {
            name: 'French Southern Territories', value: '218'
        },
        {
            name: 'Togo', value: '219'
        },
        {
            name: 'Thailand', value: '220'
        },
        {
            name: 'Tajikistan', value: '221'
        },
        {
            name: 'Tokelau', value: '222'
        },
        {
            name: 'East Timor', value: '223'
        },
        {
            name: 'Turkmenistan', value: '224'
        },
        {
            name: 'Tunisia', value: '225'
        },
        {
            name: 'Tonga', value: '226'
        },
        {
            name: 'Turkey', value: '227'
        },
        {
            name: 'Trinidad and Tobago', value: '228'
        },
        {
            name: 'Tuvalu', value: '229'
        },
        {
            name: 'Taiwan', value: '230'
        },
        {
            name: 'Tanzania', value: '231'
        },
        {
            name: 'Ukraine', value: '232'
        },
        {
            name: 'Uganda', value: '233'
        },
        {
            name: 'United States Minor Outlying Islands', value: '234'
        },
        {
            name: 'United States', value: '235'
        },
        {
            name: 'Uruguay', value: '236'
        },
        {
            name: 'Uzbekistan', value: '237'
        },
        {
            name: 'Vatican', value: '238'
        },
        {
            name: 'Saint Vincent and the Grenadines', value: '239'
        },
        {
            name: 'Venezuela', value: '240'
        },
        {
            name: 'British Virgin Islands', value: '241'
        },
        {
            name: 'U.S. Virgin Islands', value: '242'
        },
        {
            name: 'Vietnam', value: '243'
        },
        {
            name: 'Vanuatu', value: '244'
        },
        {
            name: 'Wallis and Futuna', value: '245'
        },
        {
            name: 'Samoa', value: '246'
        },
        {
            name: 'Kosovo', value: '247'
        },
        {
            name: 'Yemen', value: '248'
        },
        {
            name: 'Mayotte', value: '249'
        },
        {
            name: 'South Africa', value: '250'
        },
        {
            name: 'Zambia', value: '251'
        },
        {
            name: 'Zimbabwe', value: '252'
        }
    ];
