<h2 mat-dialog-title>Edit Alternative</h2>

<mat-dialog-content [formGroup]="form">

    <mat-form-field>

        <input matInput placeholder=" Title" formControlName="name">

    </mat-form-field>

    <mat-form-field>

        <input matInput placeholder="Country" formControlName="country">

    </mat-form-field>
    <mat-form-field>

        <input matInput placeholder="Area of operation" formControlName="areaOfOperation">

    </mat-form-field>
    <mat-form-field>


        <input matInput placeholder="Link for Logo" type="text" formControlName="url">

    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Label" type="text" formControlName="label">

    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Minimal amount" type="text" formControlName="amountToInvest">

    </mat-form-field>
    <mat-form-field>

        <input matInput placeholder="Benefits of the investment" type="text" formControlName="benefitsOfTheInvestment">

    </mat-form-field>
    <mat-form-field>

        <input matInput placeholder="Link of alternative website " type="text" formControlName="Website">

    </mat-form-field>

    <mat-form-field>
        <mat-label>Overall category</mat-label>


        <mat-select matNativeControl formControlName="overallCategory">


            <mat-option value="Bank">Bank</mat-option>

            <mat-option value="Investment">Investment</mat-option>
            <mat-option value="Insurance">Insurance</mat-option>
            <mat-option value="Pension Fund">Pension Fund</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Fiscal advantager</mat-label>

        <mat-select matNativeControl formControlName="fiscalAdvantage">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>

        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>type of customer</mat-label>


        <mat-select matNativeControl formControlName="typeOfCustomer">
            <mat-option value="Retail">Retail</mat-option>
            <mat-option value="Institutional">Institutional</mat-option>

        </mat-select>
    </mat-form-field>
    <mat-form-field>

        <textarea matInput placeholder="Description" formControlName="description" class="description">

        </textarea>

    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

    <button mat-raised-button (click)="close()" class="close-form">
        Close
    </button>

    <button mat-raised-button color="primary" (click)="save()" class="save-Alternative">
        Save
    </button>

</mat-dialog-actions>