export const AllQuestion =
    [
        {
            questionOne: 'Do you have at least three months expenses set aside in areadily accessible account?', value: '1'
        },
        {
            questionTwo: 'Do you have enough money each month to pay your rent/loans and other household expenses?', value: '2'
        },
        {
            questionThree: 'Do you have enough money to pay for an emergency expense?', value: '3'
        },
        {
            questionFour: ' Do you save regularly for long-term financial goals, such as education of children, a house, or retirement etc.?', value: '4'
        },
        {
            // tslint:disable-next-line:max-line-length
            questionFive: 'Do you have a personal investment account/investment fund for retirement (other than an employee pension)?', value: '5'
        },
        {
            questionsix: 'Do you have insurance to cover "big" unexpected expenses, such as hospital bill ordisability?', value: '6'
        },
        {
            questionSeven: 'Do you already invest your money?', value: '7'
        },
        {
            questionEight: 'Do you have your money spread across more than one type of investment (e.g.,stocks, bonds, mutual funds, crowdfunding, peer to peer lending, CDs)?', value: '8'
        },
        {
            questionNine: 'Are you happy about the impact of your money in terms of sustainability?', value: '9'
        }
    ];
