<h2 mat-dialog-title>Edit initiative</h2>

<mat-dialog-content [formGroup]="form">

    <mat-form-field>

        <input matInput placeholder=" Title" formControlName="name">

    </mat-form-field>

    <mat-form-field>

        <input matInput placeholder="Country" formControlName="country">

    </mat-form-field>

    <mat-form-field>

        <input matInput placeholder="Link of initiative website " type="text" formControlName="link">

    </mat-form-field>




    <mat-form-field>

        <textarea matInput placeholder="Description" formControlName="description" class="description">

        </textarea>

    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

    <button mat-raised-button (click)="close()" class="close-form">
        Close
    </button>

    <button mat-raised-button color="primary" (click)="save()" class="save-Alternative">
        Save
    </button>

</mat-dialog-actions>