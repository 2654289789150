<h1>{{ "MEASURE_PAGE.TITLE" | translate }}</h1>
<section class="section-one">
  <img src="assets/measure.png" />
  <section class="section-two">
    <p>{{ "MEASURE_PAGE.SECTION_TWO_PARAGRAPH_ONE" | translate }}</p>
    <p>
      {{ "MEASURE_PAGE.SECTION_TWO_PARAGRAPH_TWO" | translate }}
    </p>
    <p>
      {{ "MEASURE_PAGE.SECTION_TWO_PARAGRAPH_THREE" | translate }}
    </p>
    <p>
      {{ "MEASURE_PAGE.SECTION_TWO_PARAGRAPH_FOUR" | translate
      }}<a href="https://fairfinanceguide.org/" target="_blank">
        Fair Finance Guide.</a
      >
    </p>
    <p>
      {{ "MEASURE_PAGE.SECTION_TWO_PARAGRAPH_FIVE" | translate }}
      <a
        href="https://docs.google.com/document/d/1gt2F6sldaDoWK07PjWoVI3CIuUT7jTR8qxcjcikFBj0/edit"
        target="_blank"
        >the template here.
      </a>
    </p>
    <p>
      Or check out the link
      <a href="https://www.myfairmoney.eu/fund-database" target="_blank"
        >here</a
      >
      {{ "MEASURE_PAGE.SECTION_TWO_PARAGRAPH_SIX" | translate }}
    </p>
    <p>
      And if you have any other questions, reach out to
      <a href="mailto:diane@mymoneymyplanet.org" target="_blank"
        >diane@mymoneymyplanet.org</a
      >, we’ll do our best to help you!
    </p>
  </section>
</section>
