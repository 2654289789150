<section class="section-one">
    <img src="assets/investments.png">
    <section class="section-two">
        <h1>{{ "INVESTMENTS.TITLE" | translate }}</h1>
        <p>
            {{ "INVESTMENTS.SECTION_ONE_PARAGRAPH_ONE" | translate }}
        </p>
        <p>
            {{ "INVESTMENTS.SECTION_ONE_PARAGRAPH_TWO" | translate }}
        </p>
        <p>
            {{ "INVESTMENTS.SECTION_ONE_PARAGRAPH_THREE" | translate }}
             <a routerLink="/alternatives/investments"><b>here</b></a>. {{ "INVESTMENTS.SECTION_ONE_PARAGRAPH_FOUR" | translate }}
            <a href="mailto:diane@mymoneymyplanet.org">diane@mymoneymyplanet.org</a>
        </p>
        <p>
            {{ "INVESTMENTS.SECTION_ONE_PARAGRAPH_FIVE" | translate }} <a
                routerLink="/initiatives"><b>{{ "INVESTMENTS.SECTION_ONE_PARAGRAPH_SIX" | translate }}</b></a>
                {{ "INVESTMENTS.SECTION_ONE_PARAGRAPH_SEVEN" | translate }}
        </p>
    </section>
</section>