
<section class="details-section">
  <mat-card class="alternative-card mat-elevation-z10">
    <mat-card-header>
      <mat-card-title class="title-name-of-blog">{{
        postData.title
      }}</mat-card-title>
    </mat-card-header>

    <mat-card-subtitle class="blog-info">
      <i class="fa fa-calendar" aria-hidden="true">{{
        postData.createdDate | date: "longDate"
      }}</i>
    </mat-card-subtitle>
    <img [src]="postData?.imageURL" class="image-of-blog" />

    <mat-card-content>
      <div [innerHTML]="postData.content"></div>
    </mat-card-content>
  </mat-card>
</section>
<section class="button-back-section">
  <button mat-raised-button (click)="onBack()" class="back-button">
    <mat-icon aria-hidden="false" aria-label="Example arrow_back icon"
      >arrow_back</mat-icon
    >
  </button>
</section>
