import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Initiative } from '../../model/initiative';
import { InitiativeService } from '../../services/initiative.service';
@Component({
  selector: 'app-edit-initiative-dialog',
  templateUrl: './edit-initiative-dialog.component.html',
  styleUrls: ['./edit-initiative-dialog.component.css']
})
export class EditInitiativeDialogComponent implements OnInit {
  form: FormGroup;
  initiative: Initiative;
  constructor(private dialogRef: MatDialogRef<EditInitiativeDialogComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) initiative: Initiative,
              private initiativeService: InitiativeService
  ) {
    this.initiative = initiative;
    this.form = this.fb.group({
      name: [initiative.name, Validators.required],
      description: [initiative.description, Validators.required],
      country: [initiative.country, Validators.required],
      link: [initiative.link, Validators.required],


    });
  }

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close();

  }
  save(): void {
    const changes = this.form.value;
    this.initiativeService.updataInitativeAdminById(this.initiative.id, changes)
      .subscribe(() => {
        this.dialogRef.close(changes);
      });
  }

}
